<main class="sign-inUp-wrapper d-flex flex-wrap align-items-center justify-content-center">
    <section class="sign-inUp-sec">
        <div class="container">
            <div class="sign-inUp-box">
                <div class="sign-inUp-head">
                    <div class="form-logo"><img src="assets/images/logo.png" alt=""></div>
                </div>
                <div class="sign-inUp-body">
                    <form class="theme-from" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                        <h6 class="new-pwd-lbl mb-md-4 mb-3">You will receive a link to create a new password.</h6>
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon2.png" alt=""></span>
                                <input class="form-control" formControlName="email" type="text" name="" value="" placeholder="Email Address">
                            </div>
                            <div *ngIf="submitted && forgotPasswordForm.controls.email.errors" class="error">
                                <div *ngIf="submitted && forgotPasswordForm.controls.email.errors.required">
                                    Email is required.
                                </div>
                                <div
                                    *ngIf="submitted && forgotPasswordForm.controls.email.errors.email || forgotPasswordForm.controls.email.errors.pattern">
                                    Please enter valid email.
                                </div>
                                <div *ngIf="submitted && forgotPasswordForm.controls.email.errors.maxlength">
                                    Email should not maximum 50 characters.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-btn-sub text-center">
                            <button class="btn btn-blue btn-block" type="submit">Submit</button>
                            <p class="mt-md-5 mt-4 rout-link">Back to <a href="javascript:void(0)" routerLink="/login" class="theme-link">Login</a></p>
                        </div>
                        <!-- ./ End form submit -->
                        <app-spinner></app-spinner>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>