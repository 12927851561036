import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
// import { Account } from './account';
declare var FB: any;
declare var fb: any;

@Injectable({
  providedIn: 'root'
})
export class InstaPhotoSharingService {
  // private accountSubject: BehaviorSubject<Account>;
  // public account: Observable<Account>;
token : any;
id:any;
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private http: HttpClient
  ) {
      // this.accountSubject = new BehaviorSubject<Account>(null);
      // this.account = this.accountSubject.asObservable();
  }

  // public get accountValue(): Account {
  //     return this.accountSubject.value;
  // }

  login() {
      // login with facebook then authenticate with the API to get a JWT auth token

      this.facebookLogin().subscribe(() => {
        // get return url from query parameters or default to home page
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
        
    });
  }
  facebookLogin() {
    FB.login(function(response) {
      console.log(response, 'data');
      if(response.authResponse){

      localStorage.setItem('accesstoken', response.authResponse.accessToken);
      this.token = response.authResponse.accessToken
      console.log(this.token, 'get token');
      this.id = response.authResponse.userID
      }
      // handle the response
    }, {scope: "pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,public_profile"});
    // login with facebook and return observable with fb access token on success
    return from(new Promise<any>(resolve => FB.login(resolve)))
    .pipe(concatMap(({ authResponse }) => {
        if (!authResponse) return EMPTY;
      //   this.token = authResponse.accessToken
        return of(authResponse.accessToken);
    }
    ));
  }

  checkValidToken(){
    //477116245644457//133156907232
    console.log(this.id,"this.id")
    return  this.http.get('https://graph.facebook.com/v12.0/133156907232?fields=connected_instagram_account'+ '&access_token='+localStorage.getItem('accesstoken') )
  }

  createContainer(id,image_url,caption){
      return this.http.post('https://graph.facebook.com/v12.0/'+id+'/media?image_url='+image_url+'&caption=%23'+caption+'&access_token='+localStorage.getItem('accesstoken') , HttpHeaders)
  }

  publishPhoto(id, containerId){
    return this.http.post('https://graph.facebook.com/v12.0/'+id+'/media_publish?creation_id='+containerId+'&access_token='+localStorage.getItem('accesstoken'), HttpHeaders)
  }


  logout() {
      // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
      FB.api('/me/permissions', 'delete', null, () => FB.logout());
      // this.accountSubject.next(null);
      this.router.navigate(['/login']);
  }

  uploadImageWithResize(data){
  return this.http.post(environment.apiUrl+ 'uploadImageWithResize', data);
}

}