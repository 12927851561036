import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Clarifai from 'clarifai';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ClarifaiServiceService {
	private clarifaiApi = environment.clarifaiApi;
	concepts: any = [];
	constructor(private http: HttpClient) { }

	//upload image api
	imageUpload(data) {
		return this.http.post(environment.apiUrl + 'uploadImage', data);
	}

	//get uploaded image data
	getUploadImageDetails(clarifaiId, campaignId) {
		return this.http.get(environment.apiUrl + 'getDetails/' + clarifaiId + '/' + campaignId);
	}

	notMatchedUserData(data) {
		return this.http.post(environment.apiUrl + 'getPendingDetails', data);

	}

	getProfileImage(userId, campaignId){
		return this.http.get(environment.apiUrl + 'userDetails/' + userId +'/'+ campaignId);
	}

	// clarifai image upload api
	instantLuxOffersResult(image: string, callBack: any): void {
		const app = new Clarifai.App({ apiKey: this.clarifaiApi });
		const modelId = localStorage.getItem('brand_modelId');
		let timeoutHandle: any;
		const timeoutPromise = new Promise((resolve, reject) => {
			timeoutHandle = setTimeout(() => reject(new Error('failureMessage')), 50000);
		});

		return app.models.initModel({ id: modelId, version: '' }).then(generalModel => {
			const clarifaiPromise = generalModel.predict(image);
			Promise.race([
				clarifaiPromise,
				timeoutPromise,
			]).then(response => {
				
				clearTimeout(timeoutHandle);
				localStorage.setItem('WT-response', JSON.stringify(response));
				this.concepts = response.outputs[0].data.concepts;
				callBack(this.concepts);
				return this.concepts;
			}, (error) => {
				callBack(false);
			});
		});
	}

    // clarifai image upload api for watches
	instantLuxOffersWatchResult(image: string, callBack: any): void {
		const app = new Clarifai.App({ apiKey: environment.clarifaiWatchApi });
		const modelId = localStorage.getItem('brand_modelId');
		let timeoutHandle: any;
		const timeoutPromise = new Promise((resolve, reject) => {
			timeoutHandle = setTimeout(() => reject(new Error('failureMessage')), 50000);
		});

		return app.models.initModel({ id: modelId, version: '' }).then(generalModel => {
			const clarifaiPromise = generalModel.predict(image);
			Promise.race([
				clarifaiPromise,
				timeoutPromise,
			]).then(response => {
				
				clearTimeout(timeoutHandle);
				localStorage.setItem('WT-response', JSON.stringify(response));
				this.concepts = response.outputs[0].data.concepts;
				callBack(this.concepts);
				return this.concepts;
			}, (error) => {
				callBack(false);
			});
		});
	}
}
