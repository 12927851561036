<main id="img-upl-wrap" class="img-upl-wrapper d-flex flex-wrap align-items-center justify-content-center">
    <section class="img-upl-sec">
        <div class="container">
            <div class="img-upl-box text-center match-not-found" >
                <div class="corner-left-t"  [style.background]="circleColor" ><img src="assets/images/left-corn-icon.png" alt=""><div class="left-t-in" [style.background]="circleColor"></div></div>
               <div class="corner-right-b"  [style.background]="circleColor"><img src="assets/images/right-corn-icon.png" alt=""><div class="corner-left-m-in" [style.background]="circleColor"></div></div>
                <div class="buy-now-block shadow-boxs box-blk-icons" [style.border]="borderColor" [style.background]="bgColor">
                    <div class="corner-left-m" [style.background]="circleColor"><img
                        src="assets/images/box-left-icon.png" alt="">
                    <div class="corner-left-m-in1" [style.background]="circleColor"></div>
                </div>
    
                <div class="corner-left-m r-b" [style.background]="circleColor"><img
                        src="assets/images/box-left-icon.png" alt="">
                    <div class="corner-left-m-in1" [style.background]="circleColor"></div>
                </div>

                    <div class="upload-body">
                        
                        <h4>{{'not_match_offer_message'| translate}}</h4>
                        <div class="not-found mt-4 mb-md-5 mb-md-5 mb-4">
                            <h6></h6>
                            <div class="upl-imgBox">
                                <img *ngIf="campaignPhoto != null" src="{{campaignPhoto}}" alt="">
                                <img *ngIf="campaignPhoto == null" src="assets/images/bnr-fig.png" alt="">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</main>


<!-- Modal -->
<div class="modal fade infoModal" tabindex="-1" role="dialog" aria-labelledby="colorModalLabel" aria-hidden="true"
    id="userModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <button type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <form class="theme-from" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon1.png" alt=""></span>
                            <input class="form-control" type="text" name="" formControlName="first_name" value=""
                                placeholder="{{'client_first_name' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.first_name.errors" class="error">
                            <div
                                *ngIf="submitted && contactForm.controls.first_name.errors.required || contactForm.controls.first_name.hasError('whitespace')">
                                {{'client_first_name_err_message' | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- ./ End form-group -->
                    <div class="form-group">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon1.png" alt=""></span>
                            <input class="form-control" type="text" name="" value="" formControlName="last_name"
                                placeholder="{{'client_last_name' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.last_name.errors" class="error">
                            <div
                                *ngIf="submitted && contactForm.controls.last_name.errors.required || contactForm.controls.last_name.hasError('whitespace')">
                                {{'client_last_name_err_message' | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- ./ End form-group -->
                    <div class="form-group ">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon7.png" alt=""></span>
                            <input class="form-control" type="text" name="" value="" formControlName="contact_number"
                                placeholder="{{'client_contact_number' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.contact_number.errors" class="error">
                            <div
                                *ngIf="submitted && contactForm.controls.contact_number.errors.required || contactForm.controls.contact_number.hasError('whitespace')">
                                {{'client_contact_number_err_message_required' | translate}}
                            </div>
                            <div *ngIf="submitted && contactForm.controls.contact_number.errors.pattern">
                                {{'client_contact_number_err_message_contain_number' | translate}}
                            </div>
                            <div *ngIf="submitted && contactForm.controls.contact_number.errors.maxlength">
                                {{'client_contact_number_err_message_max_12characters' | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- ./ End form-group -->
                    <div class="form-group ">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon2.png" alt=""></span>
                            <input class="form-control" type="email" name="" value="" formControlName="email"
                                placeholder="{{'client_email' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.email.errors" class="error">
                            <div *ngIf="submitted && contactForm.controls.email.errors.required">
                                {{'client_email_err_message_required' | translate}}
                            </div>
                            <div
                                *ngIf="submitted && contactForm.controls.email.errors.email || contactForm.controls.email.errors.pattern">
                                {{'client_email_err_message_valid' | translate}}
                            </div>
                            <div *ngIf="submitted && contactForm.controls.email.errors.maxlength">
                                {{'client_email_err_message_max_50characters' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary button-color">{{'client_form_submit_button' |
                            translate}}</button>
                            <!-- <button type="button" (click)="getUpdatedTextHeader()">bhai</button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>