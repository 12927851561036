<main class="main-wrapper">
    <section class="buy-now-sec sec-out-gap">
        <div class="container">


            <div class="instant-watch-offer">
                <div class="instant-watch-offer-top mb-5" [style.border]="borderColor" [style.background]="bgColor">
                    <div class="corner-left-m" [style.background]="circleColor"><img
                            src="assets/images/box-left-icon.png" alt="">
                        <div class="corner-left-m-in1" [style.background]="circleColor"></div>
                    </div>

                    <div class="corner-left-m r-b" [style.background]="circleColor"><img
                            src="assets/images/box-left-icon.png" alt="">
                        <div class="corner-left-m-in1" [style.background]="circleColor"></div>
                    </div>
                    <div class="upload-head mb-4">
                        <div class="upload-logo"><img style="max-width: 150px;" src="{{companyLogo}}" alt=""></div>
                        <h4>{{'instant_offer_send_header' | translate }}</h4>
                    </div>
                    <div class="row align-items-center">
                        <!-- <div class="col-md-3" *ngIf="customizedDataStatus == 'NotCustomized'">
                            <div class="instant-watch-offer-top" [style.border]="borderColor">
                                <figure class="offer-prod-fig"><img src="{{modelImage}}" alt=""></figure>
                            </div>
                        </div> -->
                        <div class="col-md-12">
                            <div class="offer-desc" [innerHtml]='description | safeHtml'>
                            </div>
                        </div>
                        <!-- <div class="col-md-9" *ngIf="customizedDataStatus == 'NotCustomized'">
                            <div class="offer-desc">
                                <h4> {{modelName}}</h4>
                                <div [innerHtml]='modelDescription | safeHtml'>
                                    {{modelDescription}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="customizedDataStatus == 'Customized'">
                            <div class="offer-desc">
                                <h4> {{modelName}}</h4>
                                <div [innerHtml]='modelDescription | safeHtml'>
                                    {{modelDescription}}
                                </div>
                            </div>
                        </div> -->
                    </div>

                    <div class="pro-review-container mt-5">
                        <div class="pro-review-col">
                            <div class="pro-review-head color-green">
                                <h4>{{'instant_offer_excellent' | translate }}</h4>
                            </div>
                            <div class="pro-review-body">
                                <p>{{updatedCurrency}}{{excellentPrice}}</p>
                            </div>
                        </div>
                        <div class="pro-review-col">
                            <div class="pro-review-head color-blue">
                                <h4>{{'instant_offer_good' | translate }}</h4>
                            </div>
                            <div class="pro-review-body">
                                <p>{{updatedCurrency}}{{goodPrice}}</p>
                            </div>
                        </div>
                        <div class="pro-review-col">
                            <div class="pro-review-head color-yellow">
                                <h4>{{'instant_offer_poor' | translate }}</h4>
                            </div>
                            <div class="pro-review-body">
                                <p>{{updatedCurrency}}{{poorPrice}}</p>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div class="shadow-boxs mb-5">
                    <div class="your-watch">
                        <h4>{{'instant_offer_sell_header' | translate }}</h4>
                        <div class="sell-watch">
                            <ul>
                                <li><a href="javascript:void(0)" class="sell-icon" [style.box-shadow]="boxShadowColors"
                                        [style.background]="bgColor"><img src="assets/images/bnr-icon2.png"
                                            alt="" /></a>
                                    <h5>{{'instant_offer_discuss_offer' | translate }}</h5>
                                </li>
                                <li><a href="javascript:void(0)" class="sell-icon" [style.box-shadow]="boxShadowColors"
                                        [style.background]="bgColor"><img src="assets/images/package-icon.png"
                                            alt="" /></a>
                                    <h5>{{'instant_offer_ship_priority_insured' | translate }}</h5>
                                </li>
                                <li><a href="javascript:void(0)" class="sell-icon" [style.box-shadow]="boxShadowColors"
                                        [style.background]="bgColor"><img src="assets/images/financial-icon.png"
                                            alt="" /></a>
                                    <h5>{{'instant_offer_get_paid' | translate }}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="shadow-boxs">
                    <div class="your-watch">
                        <h4>{{'instant_offer_discuss_my_offer_header' | translate }}</h4>
                        <p class="num-text"><a class="num-text" href="tel:{{memberNumber}}">{{memberNumber}}</a></p>
                        <div class="dis-offer-box">
                            <ul>
                                <li  style="width: 100%;"><a href="tel:{{memberNumber}}" class="sell-pro-icon" [style.border]="borderColor"
                                    [style.box-shadow]="boxShadowColor"><img src="assets/images/chat-icon.png"
                                        alt="" /></a>
                                <p class="box-space">{{'instant_offer_discuss_my_offer_message_one_change' | translate }}
                                </p>
                            </li>
                                <!-- <li><a href="javascript:void(0)" class="sell-pro-icon" [style.border]="borderColor"
                                        [style.box-shadow]="boxShadowColor"><img src="assets/images/chat-icon.png"
                                            alt="" /></a>
                                    <p class="box-space">{{'instant_offer_discuss_my_offer_message_one' | translate }}
                                    </p>
                                </li>
                                <li><a href="javascript:void(0)" class="sell-pro-icon" [style.border]="borderColor"
                                        [style.box-shadow]="boxShadowColor"><img src="assets/images/mail-icon.png"
                                            alt="" /></a>
                                    <p class="box-space">{{'instant_offer_discuss_my_offer_message_two' | translate }}
                                    </p>
                                </li>
                                <li><a href="javascript:void(0)" class="sell-pro-icon" [style.border]="borderColor"
                                        [style.box-shadow]="boxShadowColor"><img src="assets/images/plene-icon.png"
                                            alt="" /></a>
                                    <p class="box-space">{{'instant_offer_discuss_my_offer_message_three' | translate }}
                                    </p>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <span class="disclosur">{{ 'disclosur' | translate}}</span>
                </div>
            </div>
        </div>
       
    </section>
</main>

<!-- Modal -->
<div class="modal fade infoModal" tabindex="-1" role="dialog" aria-labelledby="colorModalLabel" aria-hidden="true"
    id="userModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <button type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <form class="theme-from" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon1.png" alt=""></span>
                            <input class="form-control" type="text" name="" formControlName="first_name" value=""
                                placeholder="{{'client_first_name' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.first_name.errors" class="error">
                            <div
                                *ngIf="submitted && contactForm.controls.first_name.errors.required || contactForm.controls.first_name.hasError('whitespace')">
                                {{'client_first_name_err_message' | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- ./ End form-group -->
                    <div class="form-group">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon1.png" alt=""></span>
                            <input class="form-control" type="text" name="" value="" formControlName="last_name"
                                placeholder="{{'client_last_name' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.last_name.errors" class="error">
                            <div
                                *ngIf="submitted && contactForm.controls.last_name.errors.required || contactForm.controls.last_name.hasError('whitespace')">
                                {{'client_last_name_err_message' | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- ./ End form-group -->
                    <div class="form-group ">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon7.png" alt=""></span>
                            <input class="form-control" type="text" name="" value="" formControlName="contact_number"
                                placeholder="{{'client_contact_number' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.contact_number.errors" class="error">
                            <div
                                *ngIf="submitted && contactForm.controls.contact_number.errors.required || contactForm.controls.contact_number.hasError('whitespace')">
                                {{'client_contact_number_err_message_required' | translate}}
                            </div>
                            <div *ngIf="submitted && contactForm.controls.contact_number.errors.pattern">
                                {{'client_contact_number_err_message_contain_number' | translate}}
                            </div>
                            <div *ngIf="submitted && contactForm.controls.contact_number.errors.maxlength">
                                {{'client_contact_number_err_message_max_12characters' | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- ./ End form-group -->
                    <div class="form-group ">
                        <div class="field--icon">
                            <span class="field-icon"><img src="assets/images/form-icon2.png" alt=""></span>
                            <input class="form-control" type="email" name="" value="" formControlName="email"
                                placeholder="{{'client_email' | translate}}">
                        </div>
                        <div *ngIf="submitted && contactForm.controls.email.errors" class="error">
                            <div *ngIf="submitted && contactForm.controls.email.errors.required">
                                {{'client_email_err_message_required' | translate}}
                            </div>
                            <div
                                *ngIf="submitted && contactForm.controls.email.errors.email || contactForm.controls.email.errors.pattern">
                                {{'client_email_err_message_valid' | translate}}
                            </div>
                            <div *ngIf="submitted && contactForm.controls.email.errors.maxlength">
                                {{'client_email_err_message_max_50characters' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary button-color">{{'client_form_submit_button' |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>