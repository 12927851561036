import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomValidator } from 'src/app/custom-validators/custom-validator';
import * as custom from 'src/assets/js/freshdesk.js';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    resetPasswordToken: string;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toasterService: ToastrService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit(): void {
        //custom.chatBot('match');
        this.activatedRoute.params.subscribe(params => {
            this.resetPasswordToken = params.token;
            // console.log(this.resetPasswordToken);

        });
        this.resetPasswordForm = this.formBuilder.group({
            token_forget: [this.resetPasswordToken],
            new_password: ['', [
                Validators.required,
                Validators.minLength(8)]
            ],
            confirm_password: ['', [
                Validators.required]
            ]
        }, {
            validator: CustomValidator.passwordMatchValidator
        })
    }

    // submit reset password form
    onSubmit() {
        this.submitted = true;
        if (this.resetPasswordForm.invalid) {
            return;
        }
        this.spinner.show();
        this.authService.resetPassword(this.resetPasswordForm.value).subscribe(
            (res: any) => {
                if (res.error == false) {
                    this.spinner.hide();
                    this.toasterService.success(res.message);
                    this.router.navigate(['/login']);
                } else {
                    this.spinner.hide();
                    this.toasterService.error(res.message);
                }
            }
        );
    }

}
