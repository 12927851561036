import { Injectable } from '@angular/core';
import { HttpRequest,HttpHandler,HttpEvent,HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private toasterServiece: ToastrService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return  next.handle(request).pipe(catchError(err => {
  
            if (err.status === 404) {
                // this.toasterServiece.error(err.error.status);
                // this.router.navigate(['/login']);
                // localStorage.removeItem('currentUser');
            }
            if(err.status == 400 ) {
                // this.toasterServiece.error(err.error.status);
            }
            if(err.status == 403 ) {
                this.toasterServiece.error("You don't have permission to access on this server");
            }
            if(err.status == 401 ) {
                this.toasterServiece.error(err.error.message);
                this.router.navigate(['/login']);
                localStorage.removeItem('currentUser');
            }
            return throwError(err); 
        }))
    }
}
