import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    currentUser = {};
    constructor(
        private http: HttpClient
    ) { }

    // login user
    login(data): Observable<any> {
        return this.http.post(environment.apiUrl+ 'login', data).pipe(tap((res: any) => {
            if (res.error == true) {
                localStorage.setItem('currentUser', JSON.stringify(res['token']));
                this.currentUser = res.data;      
                return res;
            }
        }));
    }

    // get logged user details using token
    getUserInfo(): Observable<any> {
        return this.http.get(environment.apiUrl+ 'userByToken');
    }

    // register member 
    register(data){
        return this.http.post(environment.apiUrl + 'register', data);
    }

    // forgot password 
    forgotPassword(data) {
        return this.http.post(environment.apiUrl + 'forgetPassword', data);
    }

    // reset password
    resetPassword(data) {
        return this.http.post(environment.apiUrl + 'changePassword', data);
    }
}
