import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';

import * as custom from 'src/assets/js/freshdesk.js';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomValidator } from 'src/app/custom-validators/custom-validator';

declare var $: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    submitted: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private toasterService: ToastrService,
        private spinner: NgxSpinnerService,
        private cookieService: CookieService
    ) { }

    ngOnInit(): void {
        this.spinner.hide();
        if(this.router.url === '/login'){
            //custom.chatBot('match');
        }else{
            // console.log('should remove the js');
            
        }
        this.loginForm = this.formBuilder.group({
            email: ['', [
                Validators.required,
                // CustomValidator.removeSpaces,
                // CustomValidator.isWhitespace,
                Validators.email,
                Validators.maxLength(50),
                Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')]
            ],
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(16)]
            ]
        });
        // get login credentials of user
        this.loginForm.patchValue({
            email: window.atob(this.cookieService.get('اسم')),
            password: window.atob(this.cookieService.get('كلمه السر'))
        })
        // $('html, body').css({
        //     overflow: 'auto',
        //     height: 'auto'
        // });
    }

    // submit login user form
    login() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.spinner.show();
        this.authService.login(this.loginForm.value).subscribe(
            (res: any) => {
                if (res.error == true) {
                    this.spinner.hide();
                    this.toasterService.success(res.message);
                    this.router.navigate(['/campaign/list']);
                    // window.location.href="https://qualifiedluxuryleads.lpages.co/its-the-final-countdown/";
                    var OneSignal = window['OneSignal'] || [];
                      console.log("Init OneSignal");
                      OneSignal.push(["init", {
                        appId: "b78f305d-4a0b-4877-a470-afa57553f767",
                        autoRegister: true,
                        allowLocalhostAsSecureOrigin: true,
                        notifyButton: {
                          enable: false
                        }
                      }]);
                      console.log('OneSignal Initialized');
                      OneSignal.push(function () {
                        console.log('Register For Push');
                        OneSignal.push(["registerForPushNotifications"])
                      });
                      OneSignal.push(function () {
                        // Occurs when the user's subscription changes to a new value.
                        OneSignal.on('subscriptionChange', function (isSubscribed) {
                          console.log("The user's subscription state is now:", isSubscribed);
                          OneSignal.getUserId().then(function (userId) {
                            console.log("User ID is", userId);
                          });
                        });
                      });
                } else {
                    this.spinner.hide();
                    this.toasterService.error(res.message);
                }
            }
        );
    }

    rememberMe(rememberPasswordStatus) {
        if (rememberPasswordStatus) {
            this.cookieService.set('اسم', window.btoa(this.loginForm.value.email));
            this.cookieService.set('كلمه السر', window.btoa(this.loginForm.value.password));
        } else {
            this.cookieService.set('اسم', '');
            this.cookieService.set('كلمه السر', '');
        }
    }
}