import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

import { ClarifaiServiceService } from 'src/app/services/clarifai-service.service';
import { CampaignService } from 'src/app/services/campaign.service';
@Component({
	selector: 'app-instant-lux-offers',
	templateUrl: './instant-lux-offers.component.html',
	styleUrls: ['./instant-lux-offers.component.css']
})
export class InstantLuxOffersComponent implements OnInit {

	showimageuploader = true;
	croppedImage: any = '';
	imageChangedEvent: any = '';
	showCropper = false;
	fileToUpload: File = null;
	cropperMinWidth: number = 70;
	cropperMinHeight: number = 70;
	zone: any;
	fileData: File;
	isBackButtonClick = false;
	valueLow = 0.99;
	valueHigh = 1;
	campaignLogo: any;
	brand: any;
	modelType: any;
	modelNumber: any;
	clientFormStatus: any;
	campaignId: any;
	category: any;
	platform: any;
	modelId: any;
	accessStatus: any;
	memberId: any;
	bgColor: any;
	buttonColor: any;
	borderColor: any;
	boxShadowColor: any;
	circleColor: any;
	showPage: boolean = false;
	uploadImage: any;
	check: boolean = false;
	showCropButton: boolean = false;
	showCropperImageDiv: boolean = false;
	memberName: any;
	constructor(
		private ngZone: NgZone,
		private clarifaiService: ClarifaiServiceService,
		private toasterService: ToastrService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private campaignService: CampaignService,
		public translate: TranslateService,
	) {
		translate.addLangs(['en', 'de']);
		translate.setDefaultLang('en');
	}

	ngOnInit(): void {
		this.spinner.show();
		this.campaignId = this.activatedRoute.snapshot.paramMap.get('id'),
			this.accessStatus = this.activatedRoute.snapshot.paramMap.get('status'),
			this.platform = this.activatedRoute.snapshot.paramMap.get('platform')
		// this.activatedRoute.queryParams.subscribe(params => {
		// 	console.log(params, 'pearam');
		// 	this.campaignId = params.id;
		// 	this.platform = params.platform;
		// 	this.spinner.hide();
		// })
		if (this.campaignId != undefined) {
			this.getCampaignDetails();
		}

		localStorage.removeItem('uploadImage');
	}

	getCampaignDetails() {
		this.spinner.show();
		this.campaignService.getSharedCampaign(this.campaignId, this.accessStatus).subscribe(
			(res: any) => {
				if (res.error == false) {
					this.showPage = true;
					this.category = res.data[0].category_id;
					this.campaignLogo = res.data[0].campaign_photo;
					if(res.data[0].logged_user_detail.business_name && res.data[0].logged_user_detail.business_name != 'null'){
						this.memberName = res.data[0].logged_user_detail.business_name.replace(/[^A-Z0-9]+/ig, "-");
					}else{
						this.memberName = res.data[0].logged_user_detail.first_name.replace(/[^A-Z0-9]+/ig, "")+ "-"+res.data[0].logged_user_detail.last_name.replace(/[^A-Z0-9]+/ig, "");
					}
					this.memberId = res.data[0].member_id,
					this.brand = res.data[0].brands_details['brand_name'],
					this.modelType = res.data[0].customized_model_numbers.luxury_models.model_name
					this.modelNumber = res.data[0].customized_model_numbers.luxury_model_numbers.model_number_name,
					this.clientFormStatus = res.data[0].reg_status,
					this.bgColor = res.data[0].background_color,
					this.borderColor = '1px solid ' + res.data[0].button_color
					this.buttonColor = res.data[0].text_color
					this.boxShadowColor = '0px 0px 10.5px ' + res.data[0].button_color
					this.circleColor = res.data[0].circle_color
					this.translate.use(res.data[0].customized_model_numbers.custom_desc_lang);
					this.spinner.hide();
				} else {
					this.showPage = false;
					this.spinner.hide();
					this.toasterService.error('your campaign is deactivated');
				}
			}
		)
	}


	onFileSelected(event: any): void {
		// this.eventTry = event
		var tgt = event.target;
        var files = tgt.files;

		if (FileReader && files && files.length) {
			var fr = new FileReader();
			fr.onloadend = function () {
				// console.log( "ssss", fr.result, "fff");
				this.imgSrc = fr.result;
				this.fileToUpload = this.imgSrc;
				this.urltoFile(this.fileToUpload, 'brand_image', 'jpg').then(fileObject => {
				// console.log(this.fileToUpload, fileObject, 'file objects');
				this.fileData = fileObject;
				// console.log(this.fileData, 'file data');
				this.isImageSelected = true;

		});
			}.bind(this)
			fr.readAsDataURL(files[0]);
			this.showCropButton = true;
		}


		const _URL = window.URL || (window as any).webkitURL;
		let file;
		if ((file = event.target.files[0])) {
			const img = new Image();
			img.onload = () => {
				const newW = img.width / 8;
				const newH = img.height / 8;
				this.cropperMinWidth = newW > 70 ? newW : 70;
				this.cropperMinHeight = newH > 70 ? newH : 70;
				this.imageChangedEvent = event;
			}, { orientation: true, canvas: true };
			img.src = _URL.createObjectURL(file);
			// console.log(img.src, "image source")
		}
	}

	testing(){
		alert('cropped image show called')


		if (true) {
			var fr = new FileReader();
			fr.onloadend = function () {
				// console.log( "ssss", fr.result, "fff");
				this.imgSrc = fr.result;
				this.fileToUpload = this.imgSrc;
				this.urltoFile(this.fileToUpload, 'brand_image', 'jpg').then(fileObject => {
				// console.log(this.fileToUpload, fileObject, 'file objects');
				this.fileData = fileObject;
				// console.log(this.fileData, 'file data');
				this.isImageSelected = true;

		});
			}.bind(this)
			this.showCropButton = true;
		}


		const _URL = window.URL || (window as any).webkitURL;
		let file;
		if (true) {
			const img = new Image();
			img.onload = () => {
				const newW = img.width / 8;
				const newH = img.height / 8;
				this.cropperMinWidth = newW > 70 ? newW : 70;
				this.cropperMinHeight = newH > 70 ? newH : 70;
				this.imageChangedEvent = event;
			}, { orientation: true, canvas: true };
			img.src = _URL.createObjectURL(file);
			// console.log(img.src, "image source")
		}
		
	}

	onFileSelectedd(event: any): void {
		const _URL = window.URL || (window as any).webkitURL;
		let file;
		if ((file = event.target.files[0])) {
			const img = new Image();
			img.onload = () => {
				const newW = img.width / 8;
				const newH = img.height / 8;
				this.cropperMinWidth = newW > 70 ? newW : 70;
				this.cropperMinHeight = newH > 70 ? newH : 70;
				this.imageChangedEvent = event;
			}, { orientation: true, canvas: true };
			img.src = _URL.createObjectURL(file);
		}
	}



	// onSelectFile = e => {
	// 	if (e.target.files && e.target.files.length > 0) {
	// 	  loadImage(
	// 		e.target.files[0],
	// 		img => {
	// 		  var base64data = img.toDataURL(`image/jpeg`);
	// 		  this.setState({ src: base64data });
	// 		},
	// 		{ orientation: true, canvas: true }
	// 	  );

	// 	  // const reader = new FileReader();
	// 	  // reader.addEventListener('load', () =>
	// 	  //   this.setState({ src: reader.result })
	// 	  // );
	// 	  // reader.readAsDataURL(e.target.files[0]);
	// 	}
	//   };


	onUpload() {
		this.spinner.show();
		const formData = new FormData();
		formData.append("image", this.fileData);
		this.clarifaiService.imageUpload(formData).subscribe((res: any) => {
			if (res.error == false) {
				this.uploadImage = res.Data.image_url;
				this.callClarifaiService(res.Data.image_url);
				// this.spinner.hide();
			} else {
				this.toasterService.error('Please upload the image.')
				this.spinner.hide();
			}
		});
	}


	callClarifaiService(imageUrl) {
		this.spinner.show();
		if (this.category == 1) {
			localStorage.setItem('brand_modelId', 'Watch-Test');
		}

		if (this.category == 2) {
			localStorage.setItem('brand_modelId', 'Handbag-detector-2v');
		}

		if (this.category == 3) {
			localStorage.setItem('brand_modelId', 'Sneaker-Identifyer');
		}

		if (this.category == 1) {
			this.clarifaiService.instantLuxOffersWatchResult(imageUrl, (data) => {
				this.ngZone.run(() => {
					if (!data) {
						this.toasterService.error('Something went wrong. Please try again later.');
						this.spinner.hide();
						return;
					}
					this.modelId = data[0].id;
					// console.log(data[0].value, 'value ');
					// console.log(data[0].id, 'id ');

					const status = this.between(data[0].value, 0.85, 1, data[0].name);
					localStorage.setItem('cropped_image', imageUrl);
					localStorage.setItem('WT-status', status);
					const modelData = localStorage.getItem('setTypeObject');
					const modelDataArr = JSON.parse(modelData);
					this.spinner.hide();
					// if (this.verifyType === '1') {
					// this.router.navigate(['/get-instant-offer-detail/']);
					// } else if (this.verifyType === '2') {
					//   this.router.navigate(['/result-alert/', modelDataArr[1].brand_id, modelDataArr[1].model_type_id]);
					// } else if (this.verifyType === '3') {
					//   this.router.navigate(['/result-alert/', modelDataArr[2].brand_id, modelDataArr[2].model_type_id]);
					// } else if (this.verifyType === '4') {
					//   this.router.navigate(['/result-alert/', modelDataArr[3].brand_id, modelDataArr[3].model_type_id]);
					// }
				});
			});
		}
		else {
			// localStorage.setItem('brand_modelId', 'Handbag-Detector-1V');
			this.clarifaiService.instantLuxOffersResult(imageUrl, (data) => {
				this.ngZone.run(() => {
					if (!data) {
						this.toasterService.error('Something went wrong. Please try again later.');
						this.spinner.hide();
						return;
					}
					this.modelId = data[0].id;
					// console.log(data[0].value, 'value ');
					// console.log(data[0].id, 'id ');

					const status = this.between(data[0].value, 0.70, 1, data[0].name);
					localStorage.setItem('cropped_image', imageUrl);
					localStorage.setItem('WT-status', status);
					const modelData = localStorage.getItem('setTypeObject');
					const modelDataArr = JSON.parse(modelData);
					this.spinner.hide();
					// if (this.verifyType === '1') {
					// this.router.navigate(['/get-instant-offer-detail/']);
					// } else if (this.verifyType === '2') {
					//   this.router.navigate(['/result-alert/', modelDataArr[1].brand_id, modelDataArr[1].model_type_id]);
					// } else if (this.verifyType === '3') {
					//   this.router.navigate(['/result-alert/', modelDataArr[2].brand_id, modelDataArr[2].model_type_id]);
					// } else if (this.verifyType === '4') {
					//   this.router.navigate(['/result-alert/', modelDataArr[3].brand_id, modelDataArr[3].model_type_id]);
					// }
				});
			});
		}
	}

	between(val, low, high, status) {
		// console.log(val, low, high, status, 'all data');

		if (val >= low && val <= high) {
			// console.log('positive data');
			this.spinner.hide();
			this.clarifaiService.getUploadImageDetails(this.modelId, this.campaignId).subscribe(
				(res: any) => {
					if(res.data[0].status_pending_or_active == "Not Matched"){
						let URL = 'https://'+this.memberName +'.instaluxoffers.com/offer-not-found/' + this.modelId +'/' + this.clientFormStatus + '/'+ this.platform + '/'  + this.campaignId+ '/'+  this.memberId  
						window.open(URL, '_self');
						// this.router.navigate(['/offer-not-found/' + this.modelId +'/' + this.clientFormStatus + '/'  + this.campaignId+ '/'+  this.memberId  ]);
					}else{
						let URL = 'https://'+this.memberName +'.instaluxoffers.com/instant-offer/' + this.modelId + '/' + this.clientFormStatus + '/' + this.platform + '/' + this.campaignId+ '/'+  this.memberId 
						window.open(URL, '_self');
						// this.router.navigate(['/instant-offer/' + this.modelId + '/' + this.clientFormStatus + '/' + this.platform + '/' + this.campaignId+ '/'+  this.memberId  ]);
					}
			})
					
			// this.router.navigate(['/instant-offer'], { state: { id: this.modelId, status: this.clientFormStatus, logo: this.campaignLogo, platform: this.platform, campaignId: this.campaignId, memberId: this.memberId } });

			// this.router.navigate(['/instant-offer'], { queryParams: { id: this.modelId, status : this.clientFormStatus, logo : this.campaignLogo, platform : this.platform,campaignId:this.campaignId } });
		}
		else {
			this.spinner.hide();
			let URL = 'https://'+this.memberName +'.instaluxoffers.com/offer-not-found/' + this.modelId +'/' + this.clientFormStatus + '/'+ this.platform + '/'  + this.campaignId+ '/'+  this.memberId  
						window.open(URL, '_self');
			// localStorage.setItem('uploadImage', this.uploadImage);
			// this.router.navigate(['/offer-not-found/' + this.modelId + '/' + this.clientFormStatus + '/' + this.campaignId+ '/'+  this.memberId  ]);
			// this.toasterService.error('Uploaded image is not match');
			// console.log('negative data');
		}
		return 'Unknown';
	}

	handleFileChange = (ev) => {
		const file = ev.target.files[0];
		const reader = new FileReader();
		reader.onload = function (e) {
			this.selectedFile = reader.result;
		}.bind(this);
		reader.readAsDataURL(file);
	}

	onDelete() {
		this.croppedImage = null;
		this.showCropper = false;
	}

	test(){
		this.check = true;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		this.fileToUpload = this.croppedImage;
		this.urltoFile(this.fileToUpload, 'brand_image', 'jpg').then(fileObject => {
			// console.log(this.fileToUpload, fileObject, 'file objects');
			this.fileData = fileObject;
			// console.log(this.fileData, 'file data');
		});

	}
	imageLoaded() {
		this.showCropper = true;

	}
	cropperReady() {

	}
	loadImageFailed() {

	}
	public urltoFile(url: any, filename: any, mimeType: any): any {
		mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
		return (fetch(url)
			.then((res: any): any => {
				return res.arrayBuffer();
			})
			.then((buf: any): any => {
				return new File([buf], filename + '.jpg', { type: mimeType });
			})
		);
	}

	enableClick(){
		this.showCropperImageDiv = true;
		this.showCropButton = false;
		// const _URL = window.URL || (window as any).webkitURL;
		// let file;
		// if ((file = this.eventTry.target.files[0])) {
		// 	const img = new Image();
		// 	img.onload = () => {
		// 		const newW = img.width / 8;
		// 		const newH = img.height / 8;
		// 		this.cropperMinWidth = newW > 70 ? newW : 70;
		// 		this.cropperMinHeight = newH > 70 ? newH : 70;
		// 		this.imageChangedEvent = event;
		// 	}, { orientation: true, canvas: true };
		// 	img.src = _URL.createObjectURL(file);
		// 	console.log(img.src, "image source")
		// }
	}

	// canDeactivate() {
	// 	if (this.isBackButtonClick === true) {
	// 		this.isBackButtonClick = false;
	// 		const r = confirm('Are you sure you want to leave Verification Process ?');
	// 		if (r === true) {
	// 			this.router.navigate(['/ai-modal']);
	// 		} else {
	// 			history.pushState(null, null, window.location.pathname);
	// 		}
	// 	} else {
	// 		return true;
	// 	}
	// }

}
