import { ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

export class CustomValidator {

    static passwordMatchValidator(control: AbstractControl) {
        const password: string = control.get('new_password').value; // get password from our password form control
        const confirmPassword: string = control.get('confirm_password').value; // get password from our confirmPassword form control
        // compare is the password match
        if (password !== confirmPassword) {
            // if they don't match, set an error in our confirmPassword form control
            control.get('confirm_password').setErrors({ NoPassswordMatch: true });
        }
    }

    static registerPasswordMatchValidator(control: AbstractControl) {
        const password: string = control.get('password').value; // get password from our password form control
        const confirmPassword: string = control.get('confirm_password').value; // get password from our confirmPassword form control
        // compare is the password match
        if (password !== confirmPassword) {
            // if they don't match, set an error in our confirmPassword form control
            control.get('confirm_password').setErrors({ NoPassswordMatch: true });
        }
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirm_password').value
        return pass === confirmPass ? null : { notSame: true }
    }

    // Remove white spaces
    static removeSpaces(control: FormControl) {
        if (control && control.value) {
            let removedSpaces = control.value.split(' ').join('');
            control.value !== removedSpaces && control.setValue(removedSpaces);
        }
    }

    static isWhitespace(control: AbstractControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    // start date validation
    static start_date(control: AbstractControl) {
        const date = new Date(),
            dateMonth = ('0' + (date.getMonth() + 1)).slice(-2),
            updateDateMonth = ('0' + (date.getMonth())).slice(-2),
            dateDay = ('0' + (date.getDate())).slice(-2),
            current_date = [date.getFullYear(), dateMonth, dateDay].join('-'),
            updated_date = [date.getFullYear(), updateDateMonth, dateDay].join('-'),
            launched_date = control.get('start_date').value;
        if (localStorage.getItem('check') && launched_date < current_date) {
            console.log('called startDateInValid');
            
            control.get('start_date').setErrors({ startDateInValid: true });
        } else if (launched_date < updated_date) {
            console.log('called updateStartDateInValid');

            control.get('start_date').setErrors({ updateStartDateInValid: true });
        }
        // else if(localStorage.getItem('data')){
        //     control.get('start_date').setErrors({ startDateRequired: true });
        // }
    }

    // end date validation
    static end_date(control: AbstractControl) {
        const start_date = control.get('start_date').value;
        const end_date = control.get('end_date').value;
        if (end_date < start_date) {
            control.get('end_date').setErrors({ endDateInValid: true });
        }
        // else if(localStorage.getItem('data')){
        //     control.get('end_date').setErrors({ endDateRequired: true });
        // }
    }


    // excellent price validation
    static excellent(control: AbstractControl) {
        if(control && control.get("excellent") && control.get("good") &&  control.get("poor")){
            // console.log('cleedsdfskoff');
            
        const excellent = control.get('excellent').value;
        const good = control.get('good').value;
        const poor = control.get('poor').value;
        // console.log(excellent, good, poor, 'price data');
        
        if (good > excellent ) {
        // console.log(excellent, good, poor, good > excellent,'price data goodexcellentInvalid');

            control.get('excellent').setErrors({ goodexcellentInvalid: true });
        }
        if (poor > excellent ) {
        // console.log(excellent, good, poor, 'price data poorexcellentInvalid');

            control.get('excellent').setErrors({ poorexcellentInvalid: true });
        }
        // else if(localStorage.getItem('data')){
        //     control.get('end_date').setErrors({ endDateRequired: true });
        // }
    }
    }

    // good price validation
    static good(control: AbstractControl) {
        const excellent = control.get('excellent').value;
        const good = control.get('good').value;
        const poor = control.get('poor').value;
        // if (good > excellent ) {
        // console.log(excellent, good, poor, 'price data excellentgoodInvalid');

        //     control.get('good').setErrors({ excellentgoodInvalid: true });
        // }
        if (poor > good ) {
        // console.log(excellent, good, poor, 'price data poorgoodInvalid');

            control.get('good').setErrors({ poorgoodInvalid: true });
        }
    }

    // // poor price validation
    // static poor(control: AbstractControl) {
    //     const excellent = control.get('excellent').value;
    //     const good = control.get('good').value;
    //     const poor = control.get('poor').value;
    //     if (poor > excellent ) {
    //     console.log(excellent, good, poor, 'price data excellentpoorInvalid');

    //         control.get('poor').setErrors({ excellentpoorInvalid: true });
    //     }
    //     if (poor > good ) {
    //     console.log(excellent, good, poor, 'price data goodpoorInvalid  ');

    //         control.get('poor').setErrors({ goodpoorInvalid: true });
    //     }
    // }
}