import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
// import html2canvas from 'html2canvas';@xuannghia/html2canvas
// import html2canvas from '@xuannghia/html2canvas';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { saveAsPng, saveAsJpeg } from 'save-html-as-image';

import { CampaignService } from 'src/app/services/campaign.service';
import { ClarifaiServiceService } from 'src/app/services/clarifai-service.service';
import { InstaPhotoSharingService } from 'src/app/services/insta-photo-sharing.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../services/notification.service';

declare var $: any;

@Component({
	selector: 'app-preview-campaign',
	templateUrl: './preview-campaign.component.html',
	styleUrls: ['./preview-campaign.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class PreviewCampaignComponent implements OnInit {

	width: any;
	height: any;
	excellent: any;
	good: any;
	poor: any;
	bgColor: any;
	textColor: any;
	gridColor: any;
	campaignWatchImage: any;
	description: any = "Campaign is loading";
	campaignLogo: any;
	brand: any;
	modelType: any;
	modelNumber: any;
	modelName: any;
	reg_status: any;
	campaignId: any;
	category: any;
	check: any;
	platform: any;
	accessStatus: any;
	currency: any;
	updatedCurrency
	multiLanguage: any;
	borderColor: string;
	buttonColor: any;
	boxShadowColor: string;
	circleColor: any;
	isDraft: any;
	// liveSiteUrl = 'https://rt.cisinlive.com/wcsa-campaign-as/site';
	shareUrl: any;
	shareCopyUrl: any;
	navUrl: any;
	iframeUrl: any;
	campaignName: any;
	containerId: any;
	igUserId: any;
	instaImage: any;
	loginButtonShow: boolean = true;
	data: any;
	show: boolean = true;
	header: string
	memberName: any;
	getImage: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private toasterService: ToastrService,
		private campaignService: CampaignService,
		private sanitizer: DomSanitizer,
		public translate: TranslateService,
		private spinner: NgxSpinnerService,
		public meta: Meta,
		public title: Title,
		private accountService: InstaPhotoSharingService,
		private clarifaiService: ClarifaiServiceService,
		private notificationService: NotificationService
	) {
		translate.setDefaultLang('en');
	}

	ngOnInit(): void {
		if (localStorage.getItem('currentUser')) {
			this.loginButtonShow = false;
		}
		this.campaignId = this.activatedRoute.snapshot.paramMap.get('id'),
			this.platform = this.activatedRoute.snapshot.paramMap.get('platform')
		// this.getNotifications();
		this.spinner.show();


		// setTimeout(this.getCampaignById,3000)
		this.getCampaignById();

	}

	createInstaImageUrl() {
		// this.spinner.show();
		$('.modal').modal('hide');
		$('#download').modal('show');
		// this.spinner.hide();
		let name = this.modelName
		// //var container = document.getElementById("image-wrap"); //specific element on page
		var container = document.getElementById("fullPage"); // full page 
		html2canvas(container).then(function (canvas) {
			var link = document.createElement("a");
			document.body.appendChild(link);
			link.download = name + ".png";
			link.href = canvas.toDataURL("image/png");
			link.target = '_blank';
			link.click();
			$('#download').modal('hide');
		});
	}

	getCampaignById() {
		this.campaignService.getCampaignById(this.campaignId).subscribe(
			(res: any) => {
				if (res.error == false) {
					this.data = res.data[0];
					this.campaignLogo = res.data[0].campaign_photo;
					this.description = res.data[0].customized_model_numbers['description'];
					var htmlObject = document.getElementById("desc")
					htmlObject.innerHTML = this.description;
					this.getBase64ImageFromUrl(this.campaignLogo, document.getElementById("fullpage-logo"), 'logo')
					if (htmlObject.getElementsByTagName("img") && htmlObject.getElementsByTagName("img").length > 0) {
						// htmlObject.getElementsByTagName("img")[0].style.display = "none";
						this.getBase64ImageFromUrl(htmlObject.getElementsByTagName("img")[0].src, htmlObject.getElementsByTagName("img")[0], 'descriptionLogo')

					}
					// this.memberName = this.data.logged_user_detail.business_name;
					if (res.data[0].logged_user_detail.business_name && res.data[0].logged_user_detail.business_name != 'null') {
						this.memberName = res.data[0].logged_user_detail.business_name.replace(/[^A-Z0-9]+/ig, "-");
					} else {
						this.memberName = res.data[0].logged_user_detail.first_name.replace(/[^A-Z0-9]+/ig, "") + "-" + res.data[0].logged_user_detail.last_name.replace(/[^A-Z0-9]+/ig, "");
					}
					this.shareCopyUrl = 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/copy';
					this.iframeUrl = '<iframe src="' + 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/website' + '"  width="100%" height="100%" title="Instant Lux Offers"></iframe>';
					// this.iframeUrl = '<iframe src="' + environment.liveSiteUrl + '/campaign/' + this.campaignId + '/website' + '"  width="100%" height="100%" title="Instant Lux Offers"></iframe>';
					this.width = res.data[0].width;
					this.height = res.data[0].height;
					this.excellent = res.data[0].customized_model_numbers['custom_exell_price'],
					this.good = res.data[0].customized_model_numbers['custom_good_price'],
					this.poor = res.data[0].customized_model_numbers['custom_poor_price'],
					this.bgColor = res.data[0].background_color,
					this.textColor = res.data[0].button_color,
					this.gridColor = '3px solid #' + res.data[0].text_color,
					this.bgColor = res.data[0].background_color,
					this.borderColor = '1px solid ' + res.data[0].button_color
					this.buttonColor = res.data[0].text_color
					this.boxShadowColor = '0px 0px 10.5px ' + res.data[0].button_color
					this.circleColor = res.data[0].circle_color;
					this.category = res.data[0].category_id,
					this.brand = res.data[0].brand_id,
					this.modelName = res.data[0].brands_details.brand_name + ' ' + res.data[0].luxury_models.model_name + ' ' + res.data[0].customized_model_numbers.luxury_model_numbers.model_number_name
					this.modelType = res.data[0].customized_model_numbers['model_id'],
					this.modelNumber = res.data[0].customized_model_numbers['model_type_id'],
					this.campaignWatchImage = res.data[0].customized_model_numbers['custom_watch_image'],
					this.reg_status = res.data[0].reg_status;
					this.accessStatus = res.data[0].status;
					if (res.data[0].campaign_name == null || res.data[0].campaign_name == 'null') {
						this.campaignName = ''
					}
					else {
						this.campaignName = res.data[0].campaign_name;
					}
					this.translate.use(res.data[0].customized_model_numbers.custom_desc_lang);
					this.multiLanguage = res.data[0].customized_model_numbers.custom_desc_lang,
					this.currency = res.data[0].customized_model_numbers.currency
					this.isDraft = res.data[0].is_draft;
					localStorage.setItem('is_draft', this.isDraft);
					if (this.currency == 'en_us') {
						this.updatedCurrency = '$';
					}
					if (this.currency == 'en_uk') {
						this.updatedCurrency = '£';
					}
					if (this.currency == 'de' || this.currency == 'es') {
						this.updatedCurrency = '€';
					}
					if (this.currency == 'fr' || this.currency == 'it') {
						this.updatedCurrency = '€';
					}
					if (this.currency == 'ja') {
						this.updatedCurrency = '¥';
					}
					if (this.currency == 'pt_br') {
						this.updatedCurrency = 'R$';
					}
					if (this.currency == 'mxn') {
						this.updatedCurrency = '$';
					}
					// console.log(res);
					this.meta.updateTag({ property: "og:title", content: this.modelName })
					this.meta.updateTag({ property: "og:image", content: this.campaignWatchImage })
					let description = this.description.replace(/<[^>]+>/g, '');
					// console.log(description, "description");
					this.meta.updateTag({ property: "og:description", content: description })
					// this.meta.updateTag({ property: "og:url", content: 'http://' + this.memberName + '.instaluxoffers.com:3001/campaign/' + this.campaignId + '/facebook' })
					this.share();
					// console.log('data get', document.getElementById("fullpage-logo"))
					// return

					//    this.spinner.hide();
				}
			}
		)

	}

	getBase64ImageFromUrl(imageUrl, documnetElement, type) {
		// var res = await 
		this.campaignService.getBaseImage(imageUrl).subscribe((res: any) => {
			// console.log('base image', documnetElement);
			if (res.error == false) {
				if (type == 'logo') {
					documnetElement.src = res.data;
					setTimeout(() => {
						this.spinner.hide();
					}, 4000);
				} else {
					documnetElement.src = res.data;
					// documnetElement.style.display = "block";
					setTimeout(() => {
						this.spinner.hide();
					}, 4000);
				}
			}
			else {
				if (type == 'logo') {
					documnetElement.parentElement.remove();
					documnetElement.remove();
					this.spinner.hide();
				} else {
					documnetElement.remove();
					this.spinner.hide();
				}
			}

		})

	}

	getEmailLink() {
		this.shareUrl = 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/email';
		// this.shareUrl = environment.liveSiteUrl + '/campaign/' + this.campaignId + '/email';
		// this.getNotifications();
	}

	share() {
		// this.meta.updateTag({ name: "og:url", content: 'http://' + this.memberName + '.instaluxoffers.com:3001/campaign/' + this.campaignId + '/facebook' })
		let shareCopyUrl = 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/facebook';
		// this.shareCopyUrl = environment.liveSiteUrl + '/campaign/' + this.campaignId + '/copy';
		let searchParams = new URLSearchParams();
		searchParams.set('u', shareCopyUrl);
		this.navUrl =
			'https://www.facebook.com/sharer/sharer.php?' + searchParams;

	}

	//website iframe embeded
	getIframeUrl() {
		this.iframeUrl = '<iframe src="' + 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/website' + '"  width="100%" height="100%" title="Instant Lux Offers"></iframe>';
		this.shareUrl = 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/website';
		// this.iframeUrl = '<iframe src="' + environment.liveSiteUrl + '/campaign/' + this.campaignId + '/website' + '"  width="100%" height="100%" title="Instant Lux Offers"></iframe>';
		// this.shareUrl = environment.liveSiteUrl + '/campaign/' + this.campaignId + '/website';
	}

	public facebook() {
		// this.getNotifications();
		return window.open(this.navUrl, '_blank');
	}

	copyEmbedCode(inputElement) {
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
		this.toasterService.success('campaign page embed code is copied');
		// this.getNotifications();
	}

	copySiteUrl() {
		this.shareUrl = 'https://' + this.memberName + '.instaluxoffers.com/campaign/' + this.campaignId + '/copy';
		// this.shareUrl = environment.liveSiteUrl + '/campaign/' + this.campaignId + '/copy';
		this.toasterService.success('campaign page url is copied');
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = this.shareUrl;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		// this.getNotifications();
	}

	backToCampaign() {
		this.router.navigate(['/campaign/add-edit/' + this.campaignId]);
	}

	launchCampaign() {
		if (this.accessStatus == 1) {
		this.isDraft = '0';
		// this.createInstaImageUrl();
		localStorage.setItem('is_draft', this.isDraft);
		$(".share-on").toggleClass("open-share");
		} else {
			$('#inactive').modal('show');

		}
	}

	closeModal() {
		$(".share-on").removeClass("open-share");
	}

	uploadImage() {
		if (this.platform != 'preview') {
			// console.log(this.campaignId + '/' + this.reg_status + '/' + this.platform, 'site url data');
			window.open('https://' + this.memberName + '.instaluxoffers.com/upload/' + this.campaignId + '/' + this.accessStatus + '/' + this.platform, "_blank");
		}
	}

	logout() {
		localStorage.removeItem('currentUser');
		this.router.navigate(['/login'])
	}

	removeClass() {
		var element = document.getElementById("navbarNavDropdown");
		element.classList.remove("show");
	}

	// toggle menu class for mobile view header
	toggleMenuClass() {
		$(".header-sec").toggleClass("menu");
	}

	public urltoFile(url: any, filename: any, mimeType: any): any {
		mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
		return (fetch(url)
			.then((res: any): any => {
				return res.arrayBuffer();
			})
			.then((buf: any): any => {
				return new File([buf], filename + '.jpg', { type: mimeType });
			})
		);
	}

	getNotifications() {
		let data = {
			name: "Campaign Launched",
			message: "Your Insta Lux Offer campaign launched successfully!",
			url: this.shareUrl
		}
		if (this.platform == 'email' || this.platform == 'facebook' || this.platform == 'website' || this.platform == 'copy' || this.platform == 'instagram') {
			this.notificationService.sendNotifications(data).subscribe(
				(res: any) => {
					if (res.success)
						console.log(res, "notifications send if");
					else {
						console.log(res, "notifications send else");
					}
				}
			)
		}
	}

	publishPhoto() {
		this.accountService.publishPhoto(this.igUserId, this.containerId).subscribe(
			(res: any) => {
				// console.log(res, 'suceess id');
				if (res.id) {
					this.spinner.hide();
					this.toasterService.success('Campaign successfully send to your linked Instagram Business Account')
					localStorage.removeItem('accesstoken');
				}
				if (res.error) {
					this.spinner.hide();
					this.toasterService.error(res.error.message)
					localStorage.removeItem('accesstoken');
				}

			}, (err) => {
				// console.log(err, 'check error');

				this.toasterService.error(err.error.error.error_user_msg);
				this.toasterService.error(err.error.error.message);
			}
		)
	}

	selectedModelFunction() {
		console.log(this.data, "data")
	}

	showHeader(header) {
		// console.log("called", header, this.show);

		this.show = false;
		this.header = header
	}

	copySiteUrls() {
		this.shareUrl = 'https://' + this.memberName + '.instaluxoffers.com/upload/' + this.campaignId + '/' + this.accessStatus + '/instagram';
		// this.shareUrl = environment.liveSiteUrl + '/upload/' + this.campaignId + '/' + this.accessStatus + '/instagram';
		navigator.clipboard.writeText(this.shareUrl);
		// this.shareUrl = environment.liveSiteUrl + '/preview/' + id + '/instagram';
		this.toasterService.success('campaign page url is copied for Instagram Story');
		// const selBox = document.createElement('textarea');
		// selBox.style.position = 'fixed';
		// selBox.style.left = '0';
		// selBox.style.top = '0';
		// selBox.style.opacity = '0';
		// selBox.value = this.shareUrl;
		// document.body.appendChild(selBox);
		// selBox.focus();
		// selBox.select();
		// document.execCommand('copy');
		// document.body.removeChild(selBox);
		// this.getNotifications();
	}
}