<main class="sign-inUp-wrapper d-flex flex-wrap align-items-center justify-content-center">
    <section class="sign-inUp-sec">
        <div class="container">
            <div class="sign-inUp-box">
                <div class="sign-inUp-head">
                    <div class="form-logo"><img src="assets/images/logo.png" alt=""></div>
                </div>
                <div class="sign-inUp-body">
                    <form class="theme-from" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                        <h6 class="new-pwd-lbl mb-md-4 mb-3">Change your password.</h6>
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon8.png" alt=""></span>
                                <input class="form-control" formControlName="new_password" type="password" name="" value="" placeholder="Password">
                            </div>
                            <div *ngIf="submitted && resetPasswordForm.controls.new_password.errors" class="error">
                                <div *ngIf="submitted && resetPasswordForm.controls.new_password.errors.required">
                                    Please enter password.
                                </div>
                                <div *ngIf="submitted && resetPasswordForm.controls.new_password.errors.minlength">
                                    Password should maximum 8 charachter.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon8.png" alt=""></span>
                                <input class="form-control" formControlName="confirm_password" type="password" name="" value=""
                                    placeholder="Confirm Password">
                            </div>
                            <div *ngIf="submitted && resetPasswordForm.controls.confirm_password.errors"
                                class="error">
                                <div
                                    *ngIf="submitted && resetPasswordForm.controls.confirm_password.errors.required">
                                    Please enter confirm password.
                                </div>
                                <div *ngIf="resetPasswordForm.controls.confirm_password.errors.NoPassswordMatch">
                                    Confirm password must be same as password.
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-sub text-center">
                            <button class="btn btn-blue btn-block" type="submit">Submit</button>
                            <p class="mt-md-5 mt-4 rout-link">Back to <a href="javascript:void(0)" routerLink="/login"
                                    class="theme-link">Login</a></p>
                        </div>
                        <!-- ./ End form submit -->
                        <app-spinner></app-spinner>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>