import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomValidator } from 'src/app/custom-validators/custom-validator';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    registerForm: FormGroup;
    submitted: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private toasterService: ToastrService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            first_name: ['', [
                Validators.required,
                CustomValidator.removeSpaces,
                CustomValidator.isWhitespace,]
            ],
            last_name: ['', [
                Validators.required,
                CustomValidator.removeSpaces,
                CustomValidator.isWhitespace,]
            ],
            business_name: [''],
            address: ['', [
                Validators.required]
            ],
            mobile_number: ['', [
                Validators.required,
                Validators.maxLength(12),
                Validators.pattern('^[0-9]+$'),
                CustomValidator.removeSpaces,
                CustomValidator.isWhitespace,]
            ],
            email: ['', [
                Validators.required,
                CustomValidator.removeSpaces,
                CustomValidator.isWhitespace,
                Validators.email,
                Validators.maxLength(50),
                Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')]
            ],
            password: ['', [
                Validators.required,
                CustomValidator.removeSpaces,
                CustomValidator.isWhitespace,
                Validators.minLength(6),
                Validators.maxLength(16)]
            ],
            confirm_password: ['', [
                Validators.required,]
            ],
            checkbox: [false, Validators.pattern('true')]
        }, {
            validator: CustomValidator.registerPasswordMatchValidator
        })
    }

    // submit register member form
    register() {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.spinner.show();
        this.authService.register(this.registerForm.value).subscribe(
            (res: any) => {
                if (res.error == true) {
                    this.spinner.hide();
                    this.toasterService.success(res.message)
                    this.router.navigate(['/login']);
                }
                else{
                    this.spinner.hide();
                    this.toasterService.error(res.message);
                }
            }
        );
    }
}