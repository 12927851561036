import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { AppComponent } from 'src/app/app.component';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { RegisterComponent } from 'src/app/authentication/register/register.component';
import { ResetPasswordComponent } from 'src/app/authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from 'src/app/authentication/forgot-password/forgot-password.component';
import { JwtInterceptor } from 'src/app/interceptors/jwt.interceptor';
import { ErrorInterceptor } from 'src/app/interceptors/error.interceptor';
import { InstantLuxOffersComponent } from 'src/app/instant-lux-offers/instant-lux-offers.component';
import { PreviewCampaignComponent } from 'src/app/preview-campaign/preview-campaign.component';
import { InstantOfferSendComponent } from 'src/app/instant-offer-send/instant-offer-send.component';
import { InstantOfferNotFoundPageComponent } from 'src/app/instant-offer-not-found-page/instant-offer-not-found-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { InstaPhotoSharingService } from 'src/app/services/insta-photo-sharing.service';
import { NgxColorsModule } from 'ngx-colors';
import { MetaModule, MetaLoader, MetaStaticLoader, MetaSettings, MetaService } from '@ngx-meta/core';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    InstantLuxOffersComponent,
    PreviewCampaignComponent,
    InstantOfferSendComponent,
    InstantOfferNotFoundPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    SharedModuleModule,
    BrowserAnimationsModule,
    NgxColorsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    // MetaModule.forRoot({
    //   provide: MetaLoader,
    //   useFactory: () => {

    //   }}),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    // { provide: LocationStrategy, useClass: HashLocationStrategy},
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(private metaService: MetaService) {
  //   // Configure the meta tags using the metaService
  //   this.metaService.setTitle('Your Default Title');
  //   this.metaService.setTag('description', 'Your Default Description');
  //   // Set other meta tags here
  // }
 }
