import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

import { ClarifaiServiceService } from 'src/app/services/clarifai-service.service';
import { ClientService } from 'src/app/services/client.service';
import { CustomValidator } from 'src/app/custom-validators/custom-validator';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;
@Component({
	selector: 'app-instant-offer-not-found-page',
	templateUrl: './instant-offer-not-found-page.component.html',
	styleUrls: ['./instant-offer-not-found-page.component.css']
})
export class InstantOfferNotFoundPageComponent implements OnInit {
	uploadImage: any;
	memberId: any;
	clientFormStatus: any;
	campaignId: any;
	modelId: any;
	platform: any;
	campaignPhoto: any;
	bgColor: any;
	buttonColor: any;
	borderColor: any;
	boxShadowColor: any;
	circleColor: any;
	contactForm: FormGroup;
	submitted: boolean = false;
	updatedCurrency: any;
	brandId: any;
	modelNumberId: any;
	categoryId: any;
	constructor(
		private clarifaiService: ClarifaiServiceService,
		private toasterService: ToastrService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private clientService: ClientService,
		public translate: TranslateService,
		private formBuilder: FormBuilder
	) {
		translate.setDefaultLang('en');
		this.modelId = this.activatedRoute.snapshot.paramMap.get('modelId'),
		this.campaignId = this.activatedRoute.snapshot.paramMap.get('campaignId')
		this.platform = this.activatedRoute.snapshot.paramMap.get('platform')
		this.memberId = this.activatedRoute.snapshot.paramMap.get('memberId')
		this.uploadImage = localStorage.getItem('cropped_image')
		this.clientFormStatus = this.activatedRoute.snapshot.paramMap.get('status')
		// console.log(this.modelId, this.campaignId, 'ids ', this.uploadImage);
	}

	ngOnInit(): void {
		if (this.clientFormStatus == 1 ) {
			$('#userModal').modal('show');
			$('#userModal').modal({ backdrop: 'static', keyboard: false })
			$(".img-upl-wrapper").addClass("blur");
			// console.log('called');
			// }
		} else {
			// console.log('no pop form show');
			$(".img-upl-wrapper").removeClass("blur");

		}
		// this.getNotMatchedUserData();
		this.getProfileImage();
		this.contactForm = this.formBuilder.group({
			first_name: ['', [
				Validators.required,
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,]
			],
			last_name: ['', [
				Validators.required,
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,]
			],
			contact_number: ['', [
				Validators.required,
				Validators.pattern('[- +()0-9]+'),
				Validators.maxLength(15),
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,]
			],
			email: ['', [
				Validators.required,
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,
				Validators.email,
				Validators.maxLength(50),
				Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')]
			]

		});
	}

	getProfileImage() {
		this.clarifaiService.getProfileImage(this.memberId, this.campaignId).subscribe(
			(res: any) => {
				// console.log(res);
				this.campaignPhoto = res.User.campaign_details.campaign_photo;
				// console.log(this.campaignPhoto, 'data');
				this.bgColor = res.User.campaign_details.background_color,
				this.borderColor = '1px solid ' + res.User.campaign_details.button_color
				this.buttonColor = res.User.campaign_details.text_color
				this.boxShadowColor = '0px 0px 10.5px ' + res.User.campaign_details.button_color

				this.circleColor = res.User.campaign_details.circle_color
				this.brandId = res.User.campaign_details.brand_id
				this.modelId = res.User.campaign_details.model_id
				this.modelNumberId = res.User.campaign_details.model_type_id
				this.categoryId = res.User.campaign_details.category_id
				this.translate.use(res.User.language);
			let currency = res.User.currency
				if (currency == 'en_us') {
					this.updatedCurrency = '$';
				}
				if (currency == 'en_uk') {
					this.updatedCurrency = '£';
				}
				if (currency == 'de' || currency == 'es') {
					this.updatedCurrency = '€';
				}
				if (currency == 'fr' || currency == 'it') {
					this.updatedCurrency = '€';
				}
				if (currency == 'ja') {
					this.updatedCurrency = '¥';
				}

				if (currency == 'pt_br') {
					this.updatedCurrency = 'R$';
				}
				if (currency == 'mxn') {
					this.updatedCurrency = '$';
				}
				// console.log(res.User.language);
			}
		)
	}

	onSubmit() {
		let data = {
			clarifai_id: this.modelId,
			image_url: this.uploadImage,
			campaign_id: this.campaignId,
			first_name: this.contactForm.value.first_name,
			last_name: this.contactForm.value.last_name,
			email: this.contactForm.value.email,
			contact_number: this.contactForm.value.contact_number,
			currency: this.updatedCurrency,
			category_id : this.categoryId,
			brand_id : this.brandId,
			model_id : this.modelId,
			model_number_id : this.modelNumberId,
			platform: this.platform
		};
		console.log(data, 'get data');
		
		this.submitted = true;
		if (this.contactForm.invalid) {
			return;
		}
		if (this.uploadImage) {
			this.clarifaiService.notMatchedUserData(data).subscribe(
				(res: any) => {
					if (res.error == false) {
						// console.log("We didn’t match your item with an Instant Offer. Not to worry, our experts are reviewing your request as we speak.");
						$(".img-upl-wrapper").removeClass("blur");
						$('#userModal').modal('hide');
						// this.toasterService.error("We didn’t match your item with an Instant Offer. Not to worry, our experts are reviewing your request as we speak.");
					} else {
						this.toasterService.error("Sorry did not found any match");
					}
				}
			)
		}
	}

}
