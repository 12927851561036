import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from 'src/app/shared-module/spinner/spinner.component'; 
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/es.js";
import "froala-editor/js/languages/de.js";
import "froala-editor/js/languages/ja.js";
import "froala-editor/js/languages/pt_br.js";
import "froala-editor/js/languages/fr.js";
import "froala-editor/js/languages/it.js";
import { SafeHtmlPipe } from 'src/app/safe-html.pipe';
@NgModule({
  declarations: [ SpinnerComponent,SafeHtmlPipe ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    AngularEditorModule,
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports:[
    SpinnerComponent,
    SafeHtmlPipe,
    AngularEditorModule,
    ImageCropperModule,
    TranslateModule
  
  ]
})
export class SharedModuleModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http,"./assets/i18n/", ".json");
}