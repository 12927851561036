<header class="header-sec" (click)="closeModal()" *ngIf="platform == 'preview'">
    <div class="graphic-top-nav"></div>
    <div class="graphic-bottom-nav"></div>
    <nav class="navbar navbar-header navbar-expand-lg">
        <div class="container">
            <a class="logo-brand-icon des-hide" href="javascript:void(0)" routerLink="/">
                <i class="fa fa-home"></i>
            </a>
            <a class="navbar-brand logo-brand" href="javascript:void(0)" routerLink="/">
                <img src="assets/images/logo.png" alt="LOGO">
            </a>
            <h2 class="nav-item">Insta Lux Offers</h2>
            <p class="mobi-display" *ngIf="show">Insta Lux Offers</p>
            <p class="mobi-display" *ngIf="!show">{{header}}</p>
            <button (click)="toggleMenuClass()" class="navbar-toggler tgl-menu" type="button" data-toggle="collapse"
                data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-menu navbar-collapse justify-content-lg-end " id="navbarNavDropdown"
                (click)="removeClass()">
                <ul class="navbar-nav main-menu">
                    <li *ngIf="loginButtonShow" (click)="showHeader('Product')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)" routerLink="/product">
                            Product </a>
                    </li>
                    <li *ngIf="!loginButtonShow" (click)="showHeader('Profile')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)" routerLink="/profile">
                            Profile </a>
                    </li>
                    <li *ngIf="!loginButtonShow" (click)="showHeader('My Luxury Database')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)"
                            routerLink="/database/brands"> My Luxury Database </a>
                    </li>
                    <li *ngIf="!loginButtonShow" (click)="showHeader('Create Campaign')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)"
                            routerLink="/campaign/add-edit">Create Campaign<span class="sr-only">(current)</span></a>
                    </li>
                    <li *ngIf="!loginButtonShow" (click)="showHeader('Campaign Activity')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)"
                            routerLink="/campaign/list">Campaign Activity<span class="sr-only">(current)</span></a>
                    </li>
                    <li *ngIf="loginButtonShow" (click)="showHeader('Pricing')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)"
                            routerLink="/pricing">Pricing</a>
                    </li>
                    <li *ngIf="loginButtonShow" (click)="showHeader('Contact us')" class="nav-item ">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)"
                            routerLink="/contact-us">Contact us</a>
                    </li>
                    <li *ngIf="loginButtonShow" (click)="showHeader('FAQ')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)" routerLink="/FAQ"> FAQ
                        </a>
                    </li>
                    <li *ngIf="!loginButtonShow" (click)="showHeader('Help')" class="nav-item">
                        <a class="nav-link" routerLinkActive="active" href="javascript:void(0)" routerLink="/help"> Help
                        </a>
                    </li>
                </ul>
            </div>
            <div *ngIf="loginButtonShow" class="head-action">
                <a class="btn btn-theme btn-login" routerLink="/login" href="javascript:void(0)">Login</a>
            </div>
            <div *ngIf="!loginButtonShow" class="head-action">
                <a class="btn btn-theme btn-login" (click)="logout()" href="javascript:void(0)">Logout</a>
            </div>
            <div class="head-action ml-md-4 align-items-center">
                <!-- <div class="dropdown user-action mr-2">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        <i class="fa fa-user"></i>
                    </a>
                    <ul class="dropdown-menu submenu-list" aria-labelledby="navbarDropdownMenuLink">
                        <li *ngIf="loginButtonShow"><a class="dropdown-item" href="javascript:void(0)" routerLink="/login">Login</a></li>
                        <li *ngIf="!loginButtonShow"><a class="dropdown-item" href="javascript:void(0)" routerLink="/profile">Profile</a></li>
                        <li *ngIf="!loginButtonShow" (click)="logout()"><a class="dropdown-item" href="javascript:void(0)">Logout</a></li>
                    </ul>
                </div> -->
                <a class="btn btn-border start-trail-btn" *ngIf="loginButtonShow" href="javascript:void(0)"
                    routerLink="/pricing" (click)="removeClass()">Start Free Trial</a>
            </div>
        </div>
    </nav>
</header>
<main class="main-wrapper">
    <section class="buy-now-sec sec-out-gap lux-prev">
        <div class="container" (click)="uploadImage()">
            <div class="instant-watch-offer" *ngIf="platform == 'preview'" (click)="closeModal()">
                <div class="instant-watch-offer-top mb-5" [style.border]="borderColor">
                    <div class="send-offers mb-5 text-center">
                        <h4>{{'preview_page_price_header_one' | translate }}<br>
                            {{'preview_page_price_header_two' | translate }}</h4>
                    </div>
                    <div class="pro-review-container mt-5">
                        <div class="pro-review-col">
                            <div class="pro-review-head color-green">
                                <h4>{{'instant_offer_excellent' | translate }}</h4>
                            </div>
                            <div class="pro-review-body">
                                <p>({{updatedCurrency}}{{excellent}})</p>
                            </div>
                        </div>
                        <div class="pro-review-col">
                            <div class="pro-review-head color-blue">
                                <h4>{{'instant_offer_good' | translate }}</h4>
                            </div>
                            <div class="pro-review-body">
                                <p>({{updatedCurrency}}{{good}})</p>
                            </div>
                        </div>
                        <div class="pro-review-col">
                            <div class="pro-review-head color-yellow">
                                <h4>{{'instant_offer_poor' | translate }}</h4>
                            </div>
                            <div class="pro-review-body">
                                <p>({{updatedCurrency}}{{poor}})</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="send-offers mb-5 text-center" *ngIf="platform == 'preview'" (click)="closeModal()">
                <h3 class="mb-3">{{'preview_page_ad_header' | translate }}</h3>
                <h5 class="mb-3">{{'preview_page_ad_sub_header' | translate }}</h5>
            </div>
            <div class="instant-watch-offer">
                <div id="fullPage">
                    <div class="instant-watch-offer-top mb-5" [style.border]="borderColor" (click)="closeModal()"
                        [style.background]="bgColor">
                        <div class="corner-left-m" [style.background]="textColor"><img
                                src="assets/images/box-left-icon.png" alt="">
                            <div class="corner-left-m-in1" [style.background]="circleColor"></div>
                        </div>
                        <div class="corner-left-m r-b" [style.background]="textColor"><img
                                src="assets/images/box-left-icon.png" alt="">
                            <div class="corner-left-m-in1" [style.background]="circleColor"></div>
                        </div>
                        <div class="upload-head mb-4">
                            <!-- <div class="upload-logo" *ngIf="campaignLogo"><img style="width: 20%;" src="{{campaignLogo}}" alt=""></div> -->
                            <div id="upload-class" class="upload-logo"><img id="fullpage-logo" class="imageSize" src=""
                                    alt=""></div>
                            <h4 *ngIf="campaignName != null">{{campaignName}}</h4>
                            <!-- <h4>{{'buy_messsage_header' | translate }} {{modelName}} !</h4> -->
                        </div>
                        <div class="row align-items-center">
                            <!-- <div class="col-md-3">
                            <div class="instant-watch-offer-top">
                                <figure class="offer-prod-fig"><img  src="{{campaignWatchImage}}"  alt=""></figure>
                            </div>
                        </div> -->
                            <!-- <div class="col-md-9">
                            <div class="offer-desc">
                                <h4>Rolex Submariner 16610</h4>
                                <h6>Description:</h6>
                                <p>Rolex- Cellini Cellinium Watches, Mens size, 35 mm platinum case, mother of pearl
                                    jubliee dial arabic numerals leather strap, and platinum standard buckle.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ligula mauris,
                                    efficitur vel nunc eget, efficitur finibus turpis. Phasellus scelerisque ligula
                                    nec neque vestibulum elstique. Integer id nisl id ligula finibus dictum quis at
                                    ex. Duis dui sapien, interdum non ullamcorper sed, iaculis ac erat. Suspendisse
                                    nec aliquet risus.</p>
                            </div>
                        </div> -->
                            <div class="col-md-12">
                                <div id="desc" class="fr-view">

                                </div>
                                <!-- <div class="offer-desc" id="desc" style="
                                margin-top: 40px;margin-bottom: 40px;" [innerHtml]='description | safeHtml'>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col-sm-6 text-center" *ngIf="platform == 'preview'">
                        <button type="button" class="btn  btn-sm btn-green" (click)="backToCampaign()">
                            {{'preview_back_button' | translate }}</button>
                    </div>
                    <div class="col-sm-6 text-center" *ngIf="platform == 'preview'">
                        <div class="inline">
                            <div class="share-on">
                                <h4>Share on...</h4>
                                <ul>
                                    <li><a href="javascript:void(0)" (click)="facebook()"><i
                                                class="fab fa-facebook-f"></i> Facebook</a></li>
                                    <li> <a href="javascript:void(0)" data-toggle="modal"
                                            (click)="selectedModelFunction()" data-target="#story-Center"><i
                                                class="fab fa-instagram"></i>
                                            Instagram</a></li>
                                    <li><a href="mailto:someone@yoursite.com?subject=AS you want&amp;body={{shareUrl}}"
                                            title="Share by Email" (click)="getEmailLink()"><i
                                                class="far fa-envelope"></i> E-Mail</a></li>
                                    <li><a href="javascript:void(0)" data-toggle="modal" (click)="getIframeUrl()"
                                            data-target=".embedModal"><i class="fas fa-globe-europe"></i> Website</a>
                                    </li>
                                </ul>
                                <p>Copy the url...</p>
                                <span class="copy-url" (click)="copySiteUrl()">{{shareCopyUrl}}</span>
                                <div class="up-icon"><i class="fas fa-chevron-up"></i></div>
                            </div>
                            <button type="button" class="btn  btn-sm btn-blue social-share" (click)="launchCampaign()">
                                {{'preview_launch_button' | translate }}</button>
                        </div>
                    </div>

                    <div class="col-sm-12 text-center " *ngIf="platform != 'preview'">
                        <button type="button" class="btn  btn-sm btn-green" [style.border]="borderColor"
                            [style.background]="buttonColor">
                            {{'preview_upload_image' | translate }}</button>
                    </div>
                </div>

            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="social-share text-md-right text-center">
                <ul class="share-links d-inline-flex flex-wrap">
                    <li><a href="javascript:void(0)"><span><img
                                    src="assets/images/share-icon1.png" alt=""></span></a>
                    </li>
                    <li data-toggle="modal" data-target=".embedModal"><a
                            href="javascript:void(0)"><span><img
                                    src="assets/images/share-icon2.png" alt=""></span></a>
                    </li>
                    <li (click)="copySiteUrl()"><a href="javascript:void(0)"><span><img
                                    src="assets/images/share-icon3.png" alt=""></span></a>
                    </li>
                    <li (click)="facebook()"><a href="javascript:void(0)"><span><img
                                    src="assets/images/share-icon4.png" alt=""></span></a>
                    </li>
                    <li><a href="javascript:void(0)"><span><img
                                    src="assets/images/share-icon5.png" alt=""></span></a>
                    </li>
               
                    <li (click)="getEmailLink()"><a
                            href="mailto:someone@yoursite.com?subject=AS you want&amp;body={{shareUrl}}"
                            title="Share by Email"><span><img
                                    src="assets/images/share-icon8.png" alt=""></span></a>
                    </li>
                </ul>
            </div>
        </div> -->
        <app-spinner></app-spinner>
    </section>
</main>

<!-- <button (click)="createInstaImageUrl()">click image</button> -->

<!-- embed code model -->
<div class="modal fade embedModal" tabindex="-1" role="dialog" aria-labelledby="colorModalLabel" aria-hidden="true"
    id="priceModel">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Embed code of Campaign</h5>
                <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="theme-from">
                    <div class="form-group mb-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="offer-pricing">
                                    <label>You can put this code into your website html body section where you want to
                                        show the Campaign.</label>
                                    <input name="excellent" type="text" [value]="iframeUrl" class="form-control"
                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                        placeholder="Embed code of Campaign" #userinput readonly>
                                    <p><b>Note:</b> You can add and edit width and height in the given url for the view.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button (click)="copyEmbedCode(userinput)" data-dismiss="modal" type="button"
                            class="btn btn-primary">Copy</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="story-Center" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Create Instagram Story</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="story-row">
                    <div class="story-col">
                        <h4 class="story-tit" style="padding: 0 0 25px;">
                            <!-- <a href="javascript:void(0);">Download Image</a> <i class="fa fa-info-circle"></i> -->
                            <a href="javascript:void(0);" class="create-button"><span class="show"
                                    (click)="createInstaImageUrl()">Download Image</span>

                                <span><i class="fal fa-info-circle"></i>
                                    <span class="info-tag">Download and insert your Insta Lux Offers advertisement into
                                        your Instagram Story. Or, upload any graphic design jpeg desired to your story
                                        (optional) and insert the copy link.
                                        <br><br>
                                        Note: "Download Image" is not allowed on IOS (iPhone) using the Chrome browser.
                                        You will need to download using Safari.
                                    </span>
                                </span>
                                <span class="message">(iPhone users must switch to Safari)</span>
                            </a>

                        </h4>
                        <div class="story-col-in">
                            <div class="cor-left">
                                <span class="cor-lg"></span>
                                <span class="cor-sm"></span>
                            </div>
                            <div class="cor-right">
                                <span class="cor-lg"></span>
                                <span class="cor-sm"></span>
                            </div>
                            <div class="stoty-logo">
                                <img class="company-logo" [src]="data ? data.campaign_photo : ''"
                                    *ngIf="data?.campaign_photo " alt="" />
                                <img class="company-logo" src="assets/images/img-icon.png"
                                    title="Please upload the company logo" *ngIf="!data?.campaign_photo " alt="">
                            </div>
                            <p>We are looking to buy your {{modelName ? modelName : ''}}!</p>
                            <div class="stoty-img">
                                <img [src]="data ? data.customized_model_numbers.custom_watch_image : ''" alt="" />
                            </div>
                            <a href="javascript:void(0);" class="story-img-off">Upload image for Instant offers!</a>
                        </div>
                    </div>
                    <div class="story-col">
                        <h4 class="story-tit" style="padding: 0 0 60px;">
                            <!-- <a href="javascript:void(0);">Copy Link</a> <i class="fa fa-info-circle"></i> -->
                            <a href="javascript:void(0);" class="create-button"><span (click)="copySiteUrls()"
                                    class="show">Copy Link</span>
                                <span><i class="fal fa-info-circle"></i>
                                    <span class="info-tag">Copy your advertisement link
                                        (this is the link that
                                        allows customers to upload
                                        their images and receive
                                        instant offers). Insert this link
                                        into your url sticker in your
                                        Instagram Story.
                                        It will allow viewers to click
                                        through to your upload image
                                        page.
                                    </span>
                                </span>
                                <span hidden>hide</span>
                            </a>
                        </h4>
                        <div class="story-col-in">
                            <div class="cor-left">
                                <span class="cor-lg"></span>
                                <span class="cor-sm"></span>
                            </div>
                            <div class="cor-right">
                                <span class="cor-lg"></span>
                                <span class="cor-sm"></span>
                            </div>
                            <div class="stoty-logo">
                                <img class="company-logo" [src]="data ? data.campaign_photo : ''"
                                    *ngIf="data?.campaign_photo" alt="" />
                                <img class="company-logo" src="assets/images/img-icon.png"
                                    title="Please upload the company logo" *ngIf="!data?.campaign_photo" alt="">
                            </div>
                            <h5>{{modelName ? modelName : ''}}!</h5>
                            <p>Upload image from your camera or
                                camera roll. Zoom or crop to display only
                                the area of the watch, as pictured.
                            </p>
                            <div class="stoty-img">
                                <figure class="upl-figBox upl-imgBox-in">
                                    <img *ngIf="data ? data.category_id==1 : false" src="assets/images/watch-icon.png"
                                        alt="">
                                    <img *ngIf="data ? data.category_id==2 : false" src="assets/images/shopping-bag.png"
                                        alt="">
                                    <img *ngIf="data ? data.category_id==3 : false" src="assets/images/sneakers.png"
                                        alt="">
                                </figure>
                            </div>
                            <a href="javascript:void(0);" class="story-img-off">Get Instant offers!</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade inactive" tabindex="-1" role="dialog" aria-labelledby="colorModalLabel" aria-hidden="true"
    id="inactive">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="theme-from">
                    <div class="form-group mb-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div style="text-align: center;" class="offer-pricing">
                                    <label>Please activate the campaign to launch.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade " id="download"  tabindex="-1" role="dialog" aria-labelledby="colorModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="theme-from">
                    <div class="form-group mb-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div style="text-align: center;" class="offer-pricing">
                                    <img src="assets/gif/spinner.gif">
                                    <label>Downloading in progress. This can take a minute. Do not refresh.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button data-dismiss="modal" type="button"
                   class="btn btn-primary">Ok</button>
             </div>
        </div>
    </div>
</div>