<main id="img-upl-wrap" class="img-upl-wrapper d-flex flex-wrap align-items-center justify-content-center"
   *ngIf="showPage">
   <section class="img-upl-sec">
      <div class="container">
         <div class="img-upl-box text-center" [style.border]="borderColor">
            <!-- [style.background]="bgColor" -->
            <!-- <div class="corner-left-t"  [style.background]="circleColor" ><img src="assets/images/left-corn-icon.png" alt=""><div class="left-t-in" [style.background]="circleColor"></div></div>
               <div class="corner-right-b"  [style.background]="circleColor"><img src="assets/images/right-corn-icon.png" alt=""><div class="corner-left-m-in" [style.background]="circleColor"></div></div> -->
            <div class="buy-now-block shadow-boxs box-blk-icons">
               <div class="corner-left-m" [style.background]="circleColor">
                  <img src="assets/images/box-left-icon.png" alt="">
                  <div class="corner-left-m-in1" [style.background]="circleColor"></div>
               </div>
               <div class="upload-head mb-4">
                  <div class="upload-logo"><img style="width: 100%; max-width: 150px;" [src]="campaignLogo" alt="">
                  </div>
               </div>
               <div class="upload-body">
                  <!-- <p class="icons-upload upl-desk"><span *ngIf="croppedImage" [style.background]="buttonColor" class="cancel-icon " (click)="onDelete()"><i class="fas fa-times"></i></span>
                     <span *ngIf="!croppedImage" [style.background]="buttonColor" class="camera-icon"><i class="fas fa-camera"></i><input type="file"  accept="image/*" (change)="onFileSelected($event)"  /></span></p> -->
                  <!-- <span *ngIf="!croppedImage" class="camera-icon"><i class="fas fa-camera"></i><input type="file"
                     accept="image/*" (change)="onFileSelected($event)" /></span>
                     <li class="btn-del" *ngIf="croppedImage">
                     <label for="file-upload" class="custom-file-upload" (click)="onDelete()">
                     <button type="button" class="close" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                     <img style="color: red;" src="assets/images/delete.svg" alt="" />
                     </label>
                     </li> -->
                  <h4>{{brand}} {{modelType}} {{modelNumber}}</h4>
                  <div class="upl-box-boder mt-4 mb-md-5 mb-md-5 mb-4">
                     <!-- <h6>Upload image from your camera or camera roll. Zoom or crop to display only the
                        <strong>area of the watch</strong>, as pictured.
                        </h6> -->
                     <h6 *ngIf="category == '1'">{{'image_upload_description_for_watch'| translate}}<a href="javascript:void(0);" class="create-button">
                     <span><i class="fal fa-info-circle"></i>
                        <span class="info-tag image-upload"> 
                           <ul>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_1' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_2' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_3' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_4' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_5' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_6' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_7' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_8' | translate }} </li>
                              <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_9' | translate }} </li>
                           </ul>
                        </span>
                     </span>
                  </a></h6>
                     <h6 *ngIf="category == '2'">{{'image_upload_description_for_handbag'| translate}}<a href="javascript:void(0);" class="create-button">
                        <span><i class="fal fa-info-circle"></i> <span class="info-tag image-upload"><ul>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_1' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_2' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_3' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_4' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_5' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_6' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_7' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_8' | translate }} </li>
                           <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_9' | translate }} </li>
                        </ul>
                           </span>
                        </span>
                     </a></h6>
                     <h6 *ngIf="category == '3'">{{'image_upload_description_for_sneaker'| translate}}<a href="javascript:void(0);" class="create-button">
                        <span><i class="fal fa-info-circle"></i>
                           <span class="info-tag image-upload"> 
                              <ul>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_1' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_2' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_3' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_4' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_5' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_6' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_7' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_8' | translate }} </li>
                                 <li><i class="fas fa-circle"></i>  {{'image_upload_Instruction_9' | translate }} </li>
                              </ul>
                           </span>
                        </span>
                     </a></h6>
                     <div class="upl-imgBox">
                        <figure *ngIf="!croppedImage && category == '1'" class="upl-figBox upl-imgBox-in"
                           [style.border]="borderColor" [style.box-shadow]="boxShadowColor"><img
                              src="assets/images/watch-icon.png" alt="">
                        </figure>
                        <figure *ngIf="!croppedImage && category == '2'" class="upl-figBox upl-imgBox-in"
                           [style.border]="borderColor" [style.box-shadow]="boxShadowColor"><img
                              src="assets/images/shopping-bag.png" alt="">
                        </figure>
                        <figure *ngIf="!croppedImage && category == '3'" class="upl-figBox upl-imgBox-in"
                           [style.border]="borderColor" [style.box-shadow]="boxShadowColor"><img
                              src="assets/images/sneakers.png" alt="">
                        </figure>
                        <div class="ai-model-choose">
                           <div class="cropper-wrap_">
                              <image-cropper #imageCropper [imageChangedEvent]="imageChangedEvent"
                                 [maintainAspectRatio]="false" [aspectRatio]="1 / 1" [cropperMinWidth]="cropperMinWidth"
                                 [cropperMinHeight]="cropperMinHeight" [onlyScaleDown]="false" [roundCropper]="false"
                                 format="png" outputType="base64" (imageCropped)="imageCropped($event)"
                                 (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                                 (loadImageFailed)="loadImageFailed()" [style.display]="showCropper ? null : 'none'"
                                 [alignImage]="'center'">
                              </image-cropper>
                           </div>
                           <ul class="d-flex justify-content-center">
                              <li>
                                 <div class="c-model">
                                    <div class="c-model-body">
                                       <p *ngIf="!croppedImage" class="icons-upload upl-bottom">
                                          <span *ngIf="!croppedImage" [style.background]="buttonColor"
                                             class="camera-icon"><i class="fas fa-camera"></i><input type="file"
                                                accept="image/*" (change)="onFileSelected($event)" /></span>
                                       </p>
                                       <img *ngIf="croppedImage" class="text" [src]="croppedImage" alt="" />
                                       <p class="icons-upload upl-bottom">
                                          <span class="cancel-icon" *ngIf="croppedImage"
                                             [style.background]="buttonColor" (click)="imageCropper.rotateLeft()"><i
                                                class="fas fa-sync-alt"></i></span>
                                          <!-- <img class="c-model-body" *ngIf="croppedImage" class="text" [src]="croppedImage" alt="" /> -->
                                          <span *ngIf="croppedImage" [style.background]="buttonColor"
                                             class="cancel-icon" (click)="onDelete()"><i
                                                class="fas fa-times"></i></span>
                                       </p>
                                    </div>
                                 </div>

                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <!-- <p *ngIf="croppedImage" class="icons-upload upl-bottom">
                  <span class="cancel-icon"><i class="fas fa-sync-alt"></i></span>
                  
                  <span class="camera-icon"><i class="fas fa-camera"></i><input type="file" /></span>
                  <span class="cancel-icon"><i class="fas fa-times"></i></span>
                   </p> -->
                  <!-- <p *ngIf="!croppedImage" class="icons-upload upl-bottom">
                  <span *ngIf="!croppedImage" [style.background]="buttonColor" class="camera-icon"><i class="fas fa-camera"></i><input type="file"  accept="image/*" (change)="onFileSelected($event)"  /></span></p> -->
                  <!-- <span class="cancel-icon"><i class="fas fa-times"></i></span> -->
                  <!-- <span class="camera-icon"><i class="fas fa-camera"></i><input type="file" /></span></p> -->
                  <div class="">
                     <!-- <button *ngIf="croppedImage" class="rotate upl-desk" type="button" (click)="imageCropper.rotateLeft()"><i  class="fas fa-sync-alt"></i></button> -->
                     <button class="btn btn-sm btn-blue" [style.background]="buttonColor" [style.border]="borderColor"
                        (click)="onUpload()">{{'image_upload_button' | translate}}</button>
                     <!-- <span *ngIf="croppedImage" [style.background]="buttonColor" class="cancel-icon" (click)="onDelete()"><i class="fas fa-times"></i></span> -->
                  </div>
               </div>
            </div>
         </div>
         <a href="javascript:void(0)" class="siteName">Powered by InstaLuxOffers.com</a>
      </div>
      <ngx-spinner [fullScreen]="true" color="#238dde" type="ball-clip-rotate-multiple" size="medium">
         <p style="
        color: white;
        padding-top: 50px;"><strong>{{'Getting_your_Offer' | translate}}</strong></p>
      </ngx-spinner>
   </section>
</main>