import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from 'src/app/authentication/forgot-password/forgot-password.component';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { RegisterComponent } from 'src/app/authentication/register/register.component';
import { ResetPasswordComponent } from 'src/app/authentication/reset-password/reset-password.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { InstantLuxOffersComponent } from 'src/app/instant-lux-offers/instant-lux-offers.component';
import { InstantOfferNotFoundPageComponent } from 'src/app/instant-offer-not-found-page/instant-offer-not-found-page.component';
import { InstantOfferSendComponent } from 'src/app/instant-offer-send/instant-offer-send.component';
import { PreviewCampaignComponent } from 'src/app/preview-campaign/preview-campaign.component';


const routes: Routes = [
  { path : '', loadChildren:()=> import('src/app/layout/layout.module').then(m=>m.LayoutModule) },
  { path : 'login', component: LoginComponent },
  { path : 'register', component: RegisterComponent },
  { path : 'forgot-password', component: ForgotPasswordComponent },
  { path : 'reset-password/:token', component: ResetPasswordComponent },
	{ path: 'preview/:id/:platform', component: PreviewCampaignComponent },
	{ path: 'preview', component: PreviewCampaignComponent },
	{ path: 'campaign/:id/:platform', component: PreviewCampaignComponent },
  { path: 'upload/:id/:status/:platform', component: InstantLuxOffersComponent },
  { path: 'instant-offer/:modelId/:status/:platform/:id/:memberId', component: InstantOfferSendComponent },
  { path: 'offer-not-found/:modelId/:status/:platform/:campaignId/:memberId', component: InstantOfferNotFoundPageComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
