<main class="sign-inUp-wrapper d-flex flex-wrap align-items-center justify-content-center">
    <section class="sign-inUp-sec">
        <div class="container">
            <div class="sign-inUp-box">
                <div class="sign-inUp-head">
                    <div class="form-logo"><img src="assets/images/logo.png" alt=""></div>
                </div>
                <div class="sign-inUp-body">
                    <form class="theme-from" [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon2.png" alt=""></span>
                                <input class="form-control" formControlName="email" type="text" name="" value="" placeholder="Email Address">
                            </div>
                            <div *ngIf="submitted && loginForm.controls.email.errors" class="error">
                                <div *ngIf="submitted && loginForm.controls.email.errors.required">
                                    Email is required.
                                </div>
                                <div
                                    *ngIf="submitted && loginForm.controls.email.errors.email || loginForm.controls.email.errors.pattern">
                                    Please enter valid email.
                                </div>
                                <div *ngIf="submitted && loginForm.controls.email.errors.maxlength">
                                    Email should not maximum 50 characters.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon8.png" alt=""></span>
                                <input class="form-control" formControlName="password" type="password" name="" value="" placeholder="Password" autocomplete="on">
                            </div>
                            <div *ngIf="submitted && loginForm.controls.password.errors" class="error">
                                <div *ngIf="submitted && loginForm.controls.password.errors.required">
                                    Password is required.
                                </div>
                                <div *ngIf="submitted && loginForm.controls.password.errors.minlength">
                                    Password should be minimum 8 characters.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group d-sm-flex align-items-center justify-content-between">
                            <div class="form-checkBox">
                                <input type="checkbox" (change)="rememberMe($event.target.checked)" id="remember">
                                <label for="remember">Remember me</label>
                            </div>
                            <div class="forgot-pwd">
                                <a href="javascript:void(0)" routerLink="/forgot-password" class="theme-link">Forgot Password?</a>
                            </div>
                        </div>
                        <div class="form-btn-sub text-center">
                            <button class="btn btn-blue btn-block" type="">Login</button>
                            <p class="mt-md-5 mt-4 rout-link">Don’t have an account? <a href="javascript:void(0)" routerLink="/pricing" class="theme-link">
                                    Sign up now</a></p>
                            <p class="mt-md-5 mt-4 rout-link">Go to Home page  <a href="javascript:void(0)" routerLink="/" class="theme-link">
                            Home page</a></p>
                        </div>
                        <!-- ./ End form submit -->
                        <app-spinner></app-spinner>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>