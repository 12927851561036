import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class CampaignService {

	constructor(private http: HttpClient
	) {

	}



	//get category
	getCategory() {
		return this.http.get(environment.apiUrl + 'selectAllcategories');
	}

	// get brand
	getBrand(data) {
		return this.http.post(environment.apiUrl + 'selectAllBrands', { category_id: data });
	}

	// get model type
	getModelType(data) {
		return this.http.post(environment.apiUrl + 'watchModelType', { brand_id: data });
	}

	// get model name
	getModelName(data) {
		return this.http.post(environment.apiUrl + 'watchModelName', { model_id: data });
	}

	// get offer price and title and description
	getOfferPrice(data) {
		return this.http.post(environment.apiUrl + 'watchDirectResult', { model_number_id: data });
	}

	// create campaign
	createCampaign(data) {
		return this.http.post(environment.apiUrl + 'createCampaign', data);
	}

	// get campaign by campaign id
	getCampaignById(id: number) {
		return this.http.get(environment.apiUrl + 'viewCampaign/' + id);
	}

	// update campaign
	updateCampaign(data) {
		return this.http.post(environment.apiUrl + 'updateCampaign', data);
	}

	// get campaign list
	getCampaignList(category) {
		return this.http.get(environment.apiUrl + 'listCampaign/' + category);
	}

	// active campaign
	activeCampaign(data) {
		return this.http.post(environment.apiUrl + 'activeCampaign', { campaignId: data });
	}

	// deactive campaign
	deactiveCampaign(data) {
		return this.http.post(environment.apiUrl + 'deactiveCampaign', { campaignId: data });
	}

	// upload watch image
	uploadImage(data) {
		return this.http.post(environment.apiUrl + 'uploadimage', { image: data });
	}

	// update date
	updateDate(data) {
		return this.http.post(environment.apiUrl + 'updateLaunchedExpired', data);
	}

	getSharedCampaign(id, status) {
		return this.http.get(environment.apiUrl + 'viewCampaignBasedOnStatus/' + id + '/' + status);
	}

	getBaseImage(url){
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Accept', '*/*');
		return this.http.post(environment.apiUrl+'convert-url?url='+url, {headers: headers})
	}
}
