// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://localhost:8000/'
    // apiUrl: 'https://rt.cisinlive.com/wcsa-campaign-as/server/api/', //for staging server
    apiUrl: 'https://api.instaluxoffers.com/api/', // for live
    // apiUrl: 'https://instaluxoffers.com/server/api/', // for live
    // clarifaiApi: 'bfe790f81e774d5e864cb2ec004ab36b',
    clarifaiApi: '3b2fdf9bdf534c149d9c3ea2d6a38341',
    // clarifaiWatchApi: '7326e5e4e1174eff81ad2ccf4379e4a3',595367da6e8347f8b887d066ce3f220f
    clarifaiWatchApi: '595367da6e8347f8b887d066ce3f220f',
    // liveSiteUrl:'https://rt.cisinlive.com/wcsa-campaign-as/site', // for staging server
    liveSiteUrl:'https://instaluxoffers.com', // for live
    // froalaKey: 'PYC4mA3A15A9D6B6C5C5eMRPYa1c1REe1BGQOQIc1CDBREJImD6F5F4B3E1B9A8C3F5C4==',
    //facebookAppId: '834064647283073'
    facebookAppId: '3208383626064792'
    
};
//ac78a3ecdbc67fdc995d3616f7ee9334
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
