<main class="sign-inUp-wrapper d-flex flex-wrap align-items-center justify-content-center">
    <section class="sign-inUp-sec">
        <div class="container">
            <div class="sign-inUp-box">
                <div class="sign-inUp-head">
                    <div class="form-logo"><img src="assets/images/logo.png" alt=""></div>
                </div>
                <div class="sign-inUp-body">
                    <form class="theme-from" [formGroup]="registerForm" (ngSubmit)="register()">
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon1.png" alt=""></span>
                                <input class="form-control" formControlName="first_name" type="text" name="" value=""
                                    placeholder="First Name">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.first_name.errors" class="error">
                                <div
                                    *ngIf="submitted && registerForm.controls.first_name.errors.required || registerForm.controls.first_name.hasError('whitespace')">
                                    First name is required.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon1.png" alt=""></span>
                                <input class="form-control" formControlName="last_name" type="text" name="" value=""
                                    placeholder="Last Name">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.last_name.errors" class="error">
                                <div
                                    *ngIf="submitted && registerForm.controls.last_name.errors.required || registerForm.controls.last_name.hasError('whitespace')">
                                    Last name is required.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->

                        <div class="form-group ">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon5.png" alt=""></span>
                                <input class="form-control" formControlName="business_name" type="text" name="" value=""
                                    placeholder="Business Name">
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group ">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon6.png" alt=""></span>
                                <input class="form-control" formControlName="address" type="text" name="" value=""
                                    placeholder="Address">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.address.errors" class="error">
                                <div *ngIf="submitted && registerForm.controls.address.errors.required ">
                                    Address is required.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group ">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon7.png" alt=""></span>
                                <input class="form-control" formControlName="mobile_number" type="text" name="" value=""
                                    placeholder="Phone Number">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.mobile_number.errors" class="error">
                                <div
                                    *ngIf="submitted && registerForm.controls.mobile_number.errors.required || registerForm.controls.mobile_number.hasError('whitespace')">
                                    Phone number is required.
                                </div>
                                <div *ngIf="submitted && registerForm.controls.mobile_number.errors.pattern">
                                    Phone number contains numbers only.
                                </div>
                                <div *ngIf="submitted && registerForm.controls.mobile_number.errors.maxlength">
                                    Phone number should be max 12 charachters.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group ">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon2.png" alt=""></span>
                                <input class="form-control" formControlName="email" type="text" name="" value=""
                                    placeholder="Email">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.email.errors" class="error">
                                <div *ngIf="submitted && registerForm.controls.email.errors.required">
                                    Email is required.
                                </div>
                                <div
                                    *ngIf="submitted && registerForm.controls.email.errors.email || registerForm.controls.email.errors.pattern">
                                    Please enter valid email.
                                </div>
                                <div *ngIf="submitted && registerForm.controls.email.errors.maxlength">
                                    Email should not maximum 50 characters.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group ">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon8.png" alt=""></span>
                                <input class="form-control" formControlName="password" type="password" name="" value=""
                                    placeholder="Password" autocomplete="on">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.password.errors" class="error">
                                <div *ngIf="submitted && registerForm.controls.password.errors.required">
                                    Please enter password.
                                </div>
                                <div *ngIf="submitted && registerForm.controls.password.errors.minlength">
                                    Password should maximum 8 charachter.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group ">
                            <div class="field--icon">
                                <span class="field-icon"><img src="assets/images/form-icon8.png" alt=""></span>
                                <input class="form-control" formControlName="confirm_password" type="password" name=""
                                    value="" placeholder="Confirm Password" autocomplete="on">
                            </div>
                            <div *ngIf="submitted && registerForm.controls.confirm_password.errors" class="error">
                                <div *ngIf="submitted && registerForm.controls.confirm_password.errors.required">
                                    Please enter confirm password.
                                </div>
                                <div *ngIf="registerForm.controls.confirm_password.errors.NoPassswordMatch">
                                    Confirm password must be same as password.
                                </div>
                            </div>
                        </div>
                        <!-- ./ End form-group -->
                        <div class="form-group form-checkBox">
                            <input type="checkbox" formControlName="checkbox" id="html">
                            <label for="html">I agree to the <a href="javascript:void(0)" routerLink="/t&c" class="theme-link"> Terms and
                                    conditions</a></label>
                            <div *ngIf="submitted && registerForm.controls.checkbox.errors" class="error">
                                <div *ngIf="submitted && registerForm.controls.checkbox.errors.pattern">
                                    Please select Terms and conditions checkbox.
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-sub text-center">
                            <button class="btn btn-blue btn-block" type="">Sign up</button>
                            <p class="mt-3 rout-link">Already have an account? <a routerLink="/login"
                                    href="javascript:void(0)"> Login </a></p>
                        </div>
                        <!-- ./ End form submit -->
                        <app-spinner></app-spinner>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>