import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomValidator } from 'src/app/custom-validators/custom-validator';
import * as custom from 'src/assets/js/freshdesk.js';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private toasterService: ToastrService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit(): void {
        //custom.chatBot('match');
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [
                Validators.required,
                Validators.email,
                Validators.maxLength(50),
                Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')
                // CustomValidator.removeSpaces
            ]
            ]
        });
    }

    // submit forgot password form
    onSubmit() {
        this.submitted = true;
        if (this.forgotPasswordForm.invalid) {
            return;
        }
        this.spinner.show();
        this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
            (res: any) => {
                if (res.error == false) {
                    this.spinner.hide();
                    this.toasterService.success(res.message)
                    this.router.navigate(['/login']);
                }
                else{
                    this.spinner.hide();
                    this.toasterService.error(res.message)
                }
            }
        )
    }

}
