import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

import { ClarifaiServiceService } from 'src/app/services/clarifai-service.service';
import { ClientService } from 'src/app/services/client.service';
import { CustomValidator } from 'src/app/custom-validators/custom-validator';
import { NotificationService } from '../services/notification.service';
declare var $: any;

@Component({
	selector: 'app-instant-offer-send',
	templateUrl: './instant-offer-send.component.html',
	styleUrls: ['./instant-offer-send.component.css']
})
export class InstantOfferSendComponent implements OnInit {
	contactForm: FormGroup;
	submitted: boolean = false;
	modelId: any;
	modelName: any;
	modelDescription: any;
	campaignLogo: any;
	modelImage: any;
	clientFormStatus: any;
	excellentPrice: any;
	goodPrice: any;
	poorPrice: any;
	companyLogo: any;
	categoryId: any;
	platform: any;
	brand_id: any;
	model_id: any;
	model_number_id: any;
	campaignId: any;
	memberId: any;
	bgColor: any;
	textColor: any;
	borderColor: any;
	boxShadowColor: any;
	boxShadowColors: any;
	instantOfferData = [];
	showPage: boolean = true;
	buttonColor: any;
	circleColor: any;
	currency: any;
	uploadImage: any;
	updatedCurrency: string;
	customizedDataStatus: any;
	memberNumber: any;
	showForm :any = 'show';
	modelStatus : any;
	description: any;
	constructor(
		private clarifaiService: ClarifaiServiceService,
		private toasterService: ToastrService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private clientService: ClientService,
		private formBuilder: FormBuilder,
		public translate: TranslateService,
		private notificationService: NotificationService
	) {
		if (this.router.getCurrentNavigation().extras.state != undefined) {
			// console.log((this.router.getCurrentNavigation().extras.state))
			this.modelId = this.router.getCurrentNavigation().extras.state.id,
			this.campaignId = this.router.getCurrentNavigation().extras.state.campaignId,
			this.clientFormStatus = this.router.getCurrentNavigation().extras.state.status,
			this.platform = this.router.getCurrentNavigation().extras.state.platform,
			this.memberId = this.router.getCurrentNavigation().extras.state.memberId,
			this.uploadImage = this.router.getCurrentNavigation().extras.state.uploadImage
			}
			translate.setDefaultLang('en');
			
			this.uploadImage = localStorage.getItem('cropped_image')
			// console.log(this.uploadImage, "uploadimage");
		
	}

	ngOnInit(): void {
		this.modelId = this.activatedRoute.snapshot.paramMap.get('modelId'),
		this.clientFormStatus = this.activatedRoute.snapshot.paramMap.get('status'),
		this.platform  = this.activatedRoute.snapshot.paramMap.get('platform'),
		this.campaignId = this.activatedRoute.snapshot.paramMap.get('id')
	// if(localStorage.getItem('not show register form') == 'show'){
		if (this.clientFormStatus == 1 ) {
			$('#userModal').modal('show');
			$('#userModal').modal({ backdrop: 'static', keyboard: false })
			$(".main-wrapper").addClass("blur");
			// console.log('called');
		// }
	}else{
		// console.log('no pop form show');
		$(".main-wrapper").removeClass("blur");
		
	}
		// if (!this.modelId && !this.campaignId) {
		// 	this.toasterService.error('something went wrong ');
		// }

		this.getUploadedImageDetails();
		// this.getNotMatchedUserData();

		// })

		this.contactForm = this.formBuilder.group({
			member_id: [''],
			campaign_id: [''],
			first_name: ['', [
				Validators.required,
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,]
			],
			last_name: ['', [
				Validators.required,
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,]
			],
			contact_number: ['', [
				Validators.required,
				Validators.pattern('[- +()0-9]+'),
				Validators.maxLength(15),
				// Validators.pattern('^[0-9 +-]+$'),

			]
			],
			email: ['', [
				Validators.required,
				CustomValidator.removeSpaces,
				CustomValidator.isWhitespace,
				Validators.email,
				Validators.maxLength(50),
				Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')]
			],
			status: [this.clientFormStatus],
			category_id: [this.categoryId],
			brand_id: [this.brand_id],
			model_id: [this.model_id],
			model_number_id: [this.model_number_id],
			exell_price: [this.excellentPrice],
			good_price: [this.goodPrice],
			poor_price: [this.poorPrice],
			platform: [this.platform],
			uploaded_image: [this.modelImage],
			currency: []
		});
	}

	getUploadedImageDetails() {
		this.getNotifications();
		// console.log('calledsdad',this.modelId && this.campaignId && this.uploadImage, this.modelId , this.campaignId ,this.uploadImage);
		if (this.modelId && this.campaignId && this.uploadImage) {
			
			this.clarifaiService.getUploadImageDetails(this.modelId, this.campaignId).subscribe(
				(res: any) => {
					if (res.error == false) {
						this.showPage = true;
						this.instantOfferData = res.data;
						this.brand_id = res.data[0].brand_id;
						this.model_id = res.data[0].model_id;
						this.model_number_id = res.data[0].id;
						this.categoryId = res.data[0].category_id;
						this.modelName = res.data[0].luxury_brands.brand_name+ ' '+ res.data[0].luxury_models.model_name+' '+res.data[0].model_number_name;
						this.modelDescription = res.data[0].description;
						this.companyLogo = res.data[0].campaign_photo;
						this.memberNumber = res.data[0].userDetails.mobile_number;
						this.modelImage = res.data[0].watch_image;
						// this.clientFormStatus = res.data;
						this.excellentPrice = res.data[0].exell_price;
						this.goodPrice = res.data[0].good_price;
						this.poorPrice = res.data[0].poor_price;
						this.bgColor = res.data[0].background_color,
						this.borderColor = '2px solid ' + res.data[0].button_color
						this.buttonColor = res.data[0].text_color
						this.boxShadowColor = '0px 0px 10.5px ' + res.data[0].button_color
						this.boxShadowColors = '0 0 0 14px ' + res.data[0].button_color
						this.circleColor = res.data[0].circle_color;
						this.customizedDataStatus = res.data[0].customize_or_not
						this.translate.use(res.data[0].userDetails.language);
						this.currency = res.data[0].userDetails.currency
						this.modelStatus = res.data[0].status_pending_or_active
						if(res.campaignOrwithout == 'without'){
							this.description = '<h4 style="font-size: 25px;line-height: 32px;text-align:center;">We are looking to buy your ' + this.modelName + '</h4>' + '<img style="  display: block;margin-left: auto;margin-right: auto;width: 22%;" src=' + this.modelImage + ' alt="Model Image">' + "<h4>Description:</h4> " + res.data[0].description
						}else{
							this.description = res.data[0].custom_description
						}
						// console.log(res.data[0].custom_description);
						
						if (this.currency == 'en_us') {
							this.updatedCurrency = '$';
						}
						if (this.currency == 'en_uk') {
							this.updatedCurrency = '£';
						}
						if (this.currency == 'de' || this.currency == 'es') {
							this.updatedCurrency = '€';
						}
						if (this.currency == 'fr' || this.currency == 'it') {
							this.updatedCurrency = '€';
						}
						if (this.currency == 'ja') {
							this.updatedCurrency = '¥';
						}

						if (this.currency == 'pt_br') {
							this.updatedCurrency = 'R$';
						}
						if (this.currency == 'mxn') {
							this.updatedCurrency = '$';
						}
					} else {
						// this.showPage = false;
						// this.toasterService.error('something went wrong');
						// console.log('else');
					}
				}
			)
		}
		//  else {
		// 	this.showPage = false;
		// 	this.toasterService.error('something went wrong');
		// }
	}

	getNotifications(){
		let	data = {
			name:"Campaign Launched",
			message:"You've got a new qualified luxury lead!",
			url:'https://rt.cisinlive.com/wcsa-campaign-as/site/campaign/client-list/'+ this.campaignId+'/'+this.campaignId
		}
		
		this.notificationService.sendNotifications(data).subscribe(
			(res:any)=>{
				// console.log(res,"notifications send");
				
			}
		)
	}

	getNotMatchedUserData() {
		let data = {
			clarifai_id: this.modelId,
			image_url: this.uploadImage,
			campaign_id: this.campaignId
		};
		if (this.uploadImage) {
			this.clarifaiService.notMatchedUserData(data).subscribe(
				(res: any) => {
					if (res.error == false) {
						this.showPage = false;
						this.toasterService.error("Uploaded image is not found");
					} else {
						this.showPage = false;
						this.toasterService.error("We didn't match your item with an Instant Offer. Not to worry, our experts are reviewing your request as we speak.");
					}
				}
			)
		}
	}

	onSubmit() {

		const formData = new FormData();
		formData.append("campaign_id", this.campaignId);
		formData.append("status", this.clientFormStatus);
		formData.append("category_id", this.categoryId);
		formData.append("brand_id", this.brand_id);
		formData.append("model_id", this.model_id);
		formData.append("model_number_id", this.model_number_id);
		formData.append("exell_price", this.excellentPrice);
		formData.append("good_price", this.goodPrice);
		formData.append("poor_price", this.poorPrice);
		formData.append("platform", this.platform);
		formData.append("uploaded_image", this.uploadImage);
		formData.append("first_name", this.contactForm.value.first_name);
		formData.append("last_name", this.contactForm.value.last_name);
		formData.append("email", this.contactForm.value.email);
		formData.append("contact_number", this.contactForm.value.contact_number);
		formData.append("member_id", this.memberId);
		formData.append("currency", this.updatedCurrency);
		// this.contactForm.value.member_id= this.memberId
		this.contactForm.value.campaign_id = this.campaignId
		// this.contactForm.value.first_name= this.first_name
		// this.contactForm.value.last_name= this.last_name 
		// this.contactForm.value.email= this.email
		// this.contactForm.value.contact_number= this.contact_number 
		this.contactForm.value.status = this.clientFormStatus
		this.contactForm.value.category_id = this.categoryId
		this.contactForm.value.brand_id = this.brand_id
		this.contactForm.value.model_id = this.model_id
		this.contactForm.value.model_number_id = this.model_number_id
		this.contactForm.value.exell_price = this.excellentPrice
		this.contactForm.value.good_price = this.goodPrice
		this.contactForm.value.poor_price = this.poorPrice
		this.contactForm.value.platform = this.platform
		this.contactForm.value.uploaded_image = this.uploadImage
		this.contactForm.value.currency = this.updatedCurrency
		// console.log(this.contactForm.value);
		// return;

		this.submitted = true;
		if (this.contactForm.invalid) {
			// console.log('return calda;sld');

			return;
		}
		this.clientService.clientContactForm(formData).subscribe(
			(res: any) => {
				if (res.error == false) {
					// this.toasterService.success(res.message);
					$(".main-wrapper").removeClass("blur");
					this.getUploadedImageDetails();
					this.showForm = 'hide';
					// localStorage.setItem('not show register form',this.showForm );
					$('#userModal').modal('hide');
				} else {
					this.toasterService.error(res.message);
					// this.toasterService.error('something went wrong');
				}
			}
		)
	}

}
