import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs'; 

import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class ClientService {
	public headerUpdate = new Subject();

	constructor(
		private http: HttpClient
	) { }

	// client contact form submission
	clientContactForm(data) {
		return this.http.post(environment.apiUrl+ 'campaignUsersCreate' , data);
	}

	//get client list data
	clientList(data) {
		return this.http.post(environment.apiUrl+ 'campaignUsersList' ,data);
	}

	//update dealStage
	updateDealStage(data){
		return this.http.post(environment.apiUrl+ "campaignUsersUpdate", data);
	}

	// header update
	updateHeaderTextUpdate(data){
		console.log(data, 
			'getting hedare adata');
		
		this.headerUpdate.next(data);
	}

	getImageUrl(): Observable<any> {  
		return this.headerUpdate.asObservable();  
	  } 
}
